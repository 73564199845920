.page-share, .page-credit
  .line
    background-color: #00
  #contents
    .close
      .line
        background-color: #000
      .txt
        .l
          background-color: #000
        .r
          background-color: #000
      .bg
        circle
          stroke: #000


.page-works.darkto
  filter: brightness(0.1)
  transition: filter 0.6s ease 0s

.page-works.darkfrom
  filter: brightness(0.1)
  brightnessanim(0.1, 1.0, "-works")
  animationParams(brightness-works, 0.6s, ease, 0.1s, 1, normal, forwards)


svgOffset2(304, 100, "closecircle2")
.close:hover
  transform: scale(1.2) translate3d(0, 13px, 0)
  .txt
    transform: rotateZ(180deg)

.close.run-enter

  .line
    heightanim(0px, 38px, "2")
    animationParams(heightanim2, 0.5s, ease, 0s, 1, normal, forwards)
  .bg
    circle
      rotateanim(0, 180, "closecircle")
      svgOffset(304, 100, "closecircle2")
      $rotate = rotate_closecircle 1.0s ease 0s 1 normal forwards
      $offset = offset_closecircle2 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
      animationParamsArray(($rotate $offset))
.close.run-leave
  .line
    height: 38px
    animationParams(heightanim, 0.5s, ease, 0.0s, 1, normal, forwards)
  .bg
    circle
      rotateanim(0, 180, "closecircle")
      svgOffset(304, 100, "closecircle2")
      $rotate = rotate_closecircle 1.0s ease 0s 1 normal forwards
      $offset = offset_closecircle2 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
      animationParamsArray(($rotate $offset))
.close
  transition: transform 0.3s ease 0s
  $delay = 2
  .line
    heightanim(0px, 38px)
    height: 0px
    animationParams(heightanim, 0.5s, ease, ($delay+0)s, 1, normal, forwards)
  .txt
    transition: transform 0.5s ease 0s
    fadeOpacity(0, 1, close)
    opacity: 0
    animationParams(fadeOpacity_close, 0.5s, ease, ($delay+0.2)s, 1, normal, forwards)
  .bg
    circle
      stroke-dashoffset: 304
      transform-origin: center
      rotateanim(0, 180, "closecircle1")
      svgOffset(304, 100, "closecircle1")
      $rotate = rotate_closecircle1 1.0s ease ($delay+0)s 1 normal forwards
      $offset = offset_closecircle1 0.8s EASEINOUTCIRC ($delay+0.1)s 1 normal forwards
      animationParamsArray(($rotate $offset))
      // animationParams(offset_closecircle, 0.4s, EASEINCIRC, 1.1s, 1, normal, forwards)



.close
  width: 65px
  height: 65px
  position: fixed
  top: 40px
  left: 50%
  margin-left: -32.5px
  display: block
  cursor: pointer
  z-index: 999

  .line
    position: absolute
    top: -27px
    left: 50%
    width: 1px
    background-color: #fff


  .txt
    position: relative
    width: 65px
    height: 65px
    display: block
    transform-origin: 33.5px center


    .l
      position: absolute
      top: 50%
      left: 50%
      margin-left: -9px
      margin-top: -1px
      width: 19px
      height: 1px
      background-color: #fff
      transform: matrix3d(0.71,-0.71,0.00,0,0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)
      -webkit-transform: matrix3d(0.71,-0.71,0.00,0,0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)

    .r
      position: absolute
      top: 50%
      left: 50%
      margin-left: -9px
      margin-top: -1px
      width: 19px
      height: 1px
      background-color: #fff
      transform: matrix3d(0.71,0.71,0.00,0,-0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)
      -webkit-transform: matrix3d(0.71,0.71,0.00,0,-0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)

  .bg
    position: absolute
    top: 0
    left: 1px
    z-index: 1

    circle
      fill: transparent
      stroke: #fff
      stroke-width: 0.5
      stroke-dasharray: 304



#contents
// $arrowanimtime = 0.6s
// $arrA = (widthanim $arrowanimtime ease 0.2s 1 normal forwards)
// $arrB = (widthanim $arrowanimtime ease 0.3s 4 normal forwards)
// $arrC = (widthanim $arrowanimtime ease 0.4s 5 normal forwards)
// $arrD = (widthanim $arrowanimtime ease 0.5s 6 normal forwards)

// $arr = ($arrA) ($arrB) ($arrC) ($arrD)
// animationParamsArray($arr)

  .footerArea
    background-color: #000
    width: 100%
    height: 248px



    .share:hover
      .arrow
        transform: scale(1.2) translate3d(10px, 0, 0)
      .txt
        fadeOpacity(0, 1, share_hover)
        yanim(5, 0, share_hover)
        $delay = 0.1
        $step = 0.025
        for $i in 1...10
          span:nth-of-type({$i})
            display:inline-block
            $fade = fadeOpacity_share_hover 1.0s ease ($delay+$step*$i)s 1 normal forwards
            $yanim = yanim_share_hover 1.0s ease ($delay+$step*$i)s 1 normal forwards
            animationParamsArray(($fade $yanim))
    .share.run-enter
      .arrow
        $arrowanimtime = 0.6s
        .line
          widthanim(0px, 115px)
          animationParams(widthanim, $arrowanimtime, ease, 0.2s, 1, normal, forwards)
        .line2
          leftanim(0px, 112px)
          animationParams(leftanim, $arrowanimtime, ease, 0.2s, 1, normal, forwards)
        .line3
          leftanim(0px, 112px)
          animationParams(leftanim, $arrowanimtime, ease, 0.2s, 1, normal, forwards)
        svg
          circle
            rotateanim(90, 270, "sharecircle")
            svgOffset(304, 100, "sharecircle2")
            $rotate = rotate_sharecircle 1.0s ease 0s 1 normal forwards
            $offset = offset_sharecircle2 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
            animationParamsArray(($rotate $offset))

    .share.run-leave
      .arrow
        $arrowanimtime = 0.6s
        .line
          widthanim(0px, 115px)
          animationParams(widthanim, $arrowanimtime, ease, 0.2s, 1, normal, forwards)
        .line2
          leftanim(0px, 112px)
          animationParams(leftanim, $arrowanimtime, ease, 0.2s, 1, normal, forwards)
        .line3
          leftanim(0px, 112px)
          animationParams(leftanim, $arrowanimtime, ease, 0.2s, 1, normal, forwards)
      .arrow
        svg
          circle
            rotateanim(90, 270, "sharecircle")
            svgOffset(304, 100, "sharecircle2")
            $rotate = rotate_sharecircle 1.0s ease 0s 1 normal forwards
            $offset = offset_sharecircle2 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
            animationParamsArray(($rotate $offset))
    .share
      .arrow
        transition: transform 0.5s ease 0s
        transform-origin: left center
        svg
          circle
            transform-origin: center
            stroke-dasharray: 304
    .share
      display: table
      color: #fff
      position: relative
      width: 100%
      height: 100%

      .txt
        display: table-cell
        vertical-align: middle
        text-align: center
        font-size: 15px
        line-height: 1.231
        letter-spacing: 4px
        fadeOpacity(0, 1, share)
        yanim(5, 0, share)
        $delay = 0.1
        $step = 0.025
        for $i in 1...10
          span:nth-of-type({$i})
            display:inline-block
            $fade = fadeOpacity_share 1.0s ease ($delay+$step*$i)s 1 normal forwards
            $yanim = yanim_share 1.0s ease ($delay+$step*$i)s 1 normal forwards
            animationParamsArray(($fade $yanim))


      .arrow
        display: block
        position: absolute
        top: 50%
        right: 70px
        margin-top: -32.5px
        width: 150px
        height: 65px

        .line
          position: absolute
          top: 50%
          left: 0
          width: 115px
          height: 1px
          background-color: #fff
          opacity: 0.75

        .line2
          position: absolute
          top: 50%
          left: 112px
          width: 3px
          height: 1px
          background-color: #fff
          transform: matrix3d(0.71,0.71,0.00,0,-0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)
          margin-top: -2px

        .line3
          position: absolute
          top: 50%
          left: 112px
          width: 3px
          height: 1px
          background-color: #fff
          transform: matrix3d(0.71,-0.71,0.00,0,0.71,0.71,0.00,0,0,0,1,0,0,0,0,1)
          margin-top: 1px

        .circle
          position: absolute
          top: 0
          right: 0
          width: 65px
          height: 65px

        circle
          fill: transparent
          stroke: #fff
          stroke-width: 0.5
          stroke-dasharray: 377 377




  .contentsBody
    background: url(/img/bg_01.png) 0 0 repeat


  .headerArea
    width: 100%
    height: 100vh
    position: relative
    display: table

    .tit
      display: table-cell
      vertical-align: middle
      text-align: center

      .txt
        display: block
        position: absolute
        left: -9999px

      .svg
        width: auto
        height: 23.5px
        display: inline-block

        svg
          width: auto
          height: 100%


  .creditWrap
    padding-bottom: 260px

    .tit
      padding-top: 236px
      margin-bottom: 120px
      text-align: center

      .txt
        display: block
        position: absolute
        left: -9999px

      .svg
        width: auto
        height: 22px
        display: block

        svg
          width: auto
          height: 100%
          display: block
          margin: 0 auto

    .stit
      margin-bottom: 38px
      margin-top: 100px
      width: auto
      height: 18px
      text-align: center

      img
        width: auto
        height: 100%
        display: block
        margin: 0 auto

    dl
      clearfix()

      dt
        width: floor(percentage(710 / 1440))
        padding-left: 25%
        height: 36px
        float: left
        font-size: 12px
        line-height: 1.231
        color: #888
        font-weight: 400
        letter-spacing: 2px
        text-align: right
        text-transform: uppercase
        box-sizing: border-box

        &.h60
          height: 60px

      dd
        width: floor(percentage(710 / 1440))
        height: 36px
        float: right
        font-size: 15px
        line-height: 1.231
        color: #000

        &.h60
          height: 60px

        .tm
          font-size: 10px


  .worksDetailWrap
    background: url(/img/bg_01.png) 0 0 repeat

    &.file001
      .worksDetailHeader
        background-image: url('/img/works_001_kv_01.jpg')

      .memberArea
        .tit
          background-image: url('/img/works_001_photo_04.jpg')

      .groupphoto
        background-image: url('/img/works_001_photo_05.jpg')

      .locationArea
        background-image: url('/img/works_001_photo_09.jpg')

      .worksDetailBody .titWrap .svg svg
        width: 257px
      .worksDetailBody .scriptWrap .svg svg
        height: 1303.56px
      .worksDetailBody .titWrap .svg.en svg
        width: 390px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (3126.46 / 2)px

      .worksDetailHeader .no .svg svg
        width: 593.11px

    &.file002
      .worksDetailHeader
        background-image: url('/img/works_002_kv_01.jpg')

      .memberArea

        .tit
          background-image: url('/img/works_002_photo_04.jpg')

      .groupphoto
        background-image: url('/img/works_002_photo_05.jpg')

      .locationArea
        background-image: url('/img/works_002_photo_09.jpg')

      .worksDetailBody .titWrap .svg svg
        width: 370.56px
      .worksDetailBody .scriptWrap .svg svg
        height: 2308.53px
      .worksDetailBody .titWrap .svg.en svg
        width: (829.6 / 2)px
        height: 253.56px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (5523.05 / 2)px

    &.file003
      .worksDetailHeader
        background-image: url('/img/works_003_kv_01.jpg')

      .memberArea
        .tit
          background-image: url('/img/works_003_photo_04.jpg')

      .groupphoto
        background-image: url('/img/works_003_photo_05.jpg')

      .locationArea
        background-image: url('/img/works_003_photo_09.jpg')
      .worksDetailBody .titWrap .svg svg
        width: 501.08px
      .worksDetailBody .scriptWrap .svg svg
        height: 2150px
      .worksDetailBody .titWrap .svg.en svg
        width: 452px
      .worksDetailBody .scriptWrap .svg.en svg
        height: 2413px
    &.file004
      .worksDetailBody .titWrap .svg svg
        width: 359.75px
      .worksDetailBody .scriptWrap .svg svg
        height: 2872.36px
      .worksDetailBody .titWrap .svg.en svg
        width: (892.08 / 2)px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (6795.97 / 2)px
    &.file005
      .worksDetailBody .titWrap .svg svg
        width: 129.25px
      .worksDetailBody .scriptWrap .svg svg
        height: 1958.56px
      .worksDetailBody .titWrap .svg.en svg
        width: (657.29 / 2)px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (4746.02 / 2)px
    &.file006
      .worksDetailBody .titWrap .svg svg
        width: (1011.22 / 2)px
        // height: 190px
      .worksDetailBody .scriptWrap .svg svg
        height: 2133.09px
      .worksDetailBody .titWrap .svg.en
        height: 250px
        svg
          width: (1404.71 / 2)px
          height: 250px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (5321.98 / 2)px

    &.file007
      .worksDetailBody .titWrap .svg svg
        width: 178.89px
      .worksDetailBody .scriptWrap .svg svg
        height: 2125.92px
      .worksDetailBody .titWrap .svg.en svg
        width: (891.1 / 2)px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (5603.76 / 2)px
    &.file008
      .worksDetailBody .titWrap .svg svg
        width: 205.16px
      .worksDetailBody .scriptWrap .svg svg
        height: 1486.08px
      .worksDetailBody .titWrap .svg.en svg
        width: (1373.68 / 2)px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (3196.28 / 2)px
    &.file009
      .worksDetailBody .titWrap .svg svg
        width: 244.3px
      .worksDetailBody .scriptWrap .svg svg
        height: 1759.86px
      .worksDetailBody .titWrap .svg.en  svg
        width: (547.86 / 2)px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (4111.16 / 2)px
    &.file010
      .worksDetailBody .titWrap .svg svg
        width: (884.18 / 2)px
      .worksDetailBody .scriptWrap .svg svg
        height: 1867.19px
      .worksDetailBody .titWrap .svg.en svg
        width: (907.07 / 2)px
      .worksDetailBody .scriptWrap .svg.en svg
        height: (4112.07 / 2)px
    &.file001 .worksDetailBody .scriptWrap
      // height: 1582px
    &.file002 .worksDetailBody .scriptWrap
      // height: 1980px
    &.file003 .worksDetailBody .scriptWrap
      // height: 1800px

    &.file004, &.file005, &.file006, &.file007, &.file008, &.file009, &.file010
      .worksDetailHeader
        background-image: url('/img/works_004_kv_01.jpg')

    .worksDetailHeader
      width: 100%
      height: 600px
      position: relative
      display: block
      background-position: 50% 50%
      background-repeat: no-repeat
      background-size: cover
      z-index: 3

      .no
        position: absolute
        top: 0
        left: floor(percentage(262 / 1440))

        .svg
          display: block
          width: auto
          height: 213px

          svg
            width: auto
            height: 100%



    .worksDetailBody
      min-height: 1745px
      z-index: 4

      .writer
        text-align: center
        padding-bottom: 260px

        .tit
          margin-top: 258px
          margin-bottom: 62px

          .txt
            display: block
            position: absolute
            left: -9999px

          .svg
            width: auto
            height: 21px
            display: block

            svg
              width: auto
              height: 100%
              display: block
              margin: 0 auto

        .photo
          padding-left: floor(percentage(380 / 1440))
          padding-right: floor(percentage(380 / 1440))
          margin-bottom: 52px

          img
            width: 100%
            height: auto
            box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

        .job
          font-size: 12.5px
          line-height: 1.231
          color: #888
          margin-bottom: 17px
          font-weight: 400

        .name
          height: 21px

          img
            width: auto
            height: 100%
            margin: 0 auto


      .titWrap
        position: relative
        margin-bottom: -12px
        top: -92px
        padding-left: floor(percentage(262 / 1440))

        .svg
          width: auto
          // height: 175px
          display: block

          svg
            width: auto
            // height: 100%
            height: 175px
            path
              fill: #fff
            rect
              fill: #fff

      .scriptWrap
        padding-left: floor(percentage(262 / 1440))

        .svg
          width: 590px
          height: 100%
          display: block

          svg
            width: 100%
            height: auto
            path
              fill: #fff
            rect
              fill: #fff

      .img01
        width: 328px
        position: absolute
        top: -92px
        right: floor(percentage(142 / 1440))

        img
          width: 100%
          height: auto
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

      .img02
        width: 328px
        position: absolute
        top: 722px
        right: floor(percentage(142 / 1440))

        img
          width: 100%
          height: auto
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

      .img03
        width: 328px
        position: absolute
        top: 1526px
        right: floor(percentage(142 / 1440))

        img
          width: 100%
          height: auto
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

    .memberArea
      margin-top: -325px
      z-index: 1

      .tit
        width: 100%
        height: 900px
        background-position: 50% 50%
        background-repeat: no-repeat
        background-size: cover
        position: relative

        .txt
          display: block
          position: absolute
          left: -9999px

        .svg
          width: 241.31px
          height: 23.5px
          display: block
          position: absolute
          top: 736px
          left: 50%
          margin-left: -115px

          svg
            width: auto
            height: 100%

      .groupphoto
        width: 100%
        height: 900px
        background-position: 50% 50%
        background-repeat: no-repeat
        background-size: cover
        margin-bottom: 174px

      .stit
        display: block
        height: 21px
        margin-top: 156px
        margin-bottom: 62px

        img
          width: auto
          height: 100%
          display: block
          margin: 0 auto

      .portlate
        padding-left: floor(percentage(52 / 1440))
        padding-right: floor(percentage(52 / 1440))
        clearfix()
        margin-bottom: 26px

        .left
          width: floor(percentage(682 / 1399))
          float: left

          img
            width: 100%
            height: auto
            box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

        .right
          width: floor(percentage(682 / 1399))
          float: right
          padding-top: floor(percentage(682 / 1399 * 0.666))
          position: relative

          .nameWrap
            position: absolute
            top: 50%
            left: 0
            width: 100%
            height: 50px
            margin-top: -25px
            text-align: center

          .job
            font-size: 12.5px
            line-height: 1.231
            color: #888
            margin-bottom: 17px
            font-weight: 400

          .name
            height: 21px

            img
              width: auto
              height: 100%
              margin: 0 auto

      .creditlist
        padding-left: floor(percentage(52 / 1440))
        padding-right: floor(percentage(52 / 1440))
        clearfix()

        dt
          width: floor(percentage(682 / 1399))
          height: 36px
          float: left
          font-size: 15px
          line-height: 1.231
          color: #888
          font-weight: 400
          text-align: right
          text-transform: uppercase

        dd
          width: floor(percentage(682 / 1399))
          height: 36px
          float: right
          font-size: 15px
          line-height: 1.231

    .locationArea
      margin-top: 210px
      width: 100%
      height: 525px
      position: relative
      display: table
      background-position: 50% 50%
      background-repeat: no-repeat
      background-size: cover

      .tit
        display: table-cell
        vertical-align: middle
        text-align: center

        .txt
          display: block
          position: absolute
          left: -9999px

        .svg
          width: auto
          height: 23.5px
          display: inline-block

          svg
            width: auto
            height: 100%

    .mapArea
      height: 828px
      padding-left: floor(percentage(52 / 1440))
      padding-right: floor(percentage(52 / 1440))
      box-sizing: border-box
      background-color: #00000010
      margin-bottom: 80px

      .mapInner
        width: 100%
        height: 852px
        background-color: #aaa
        top: -52px
        z-index: 2
        box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

        #map
          width: 100%
          height: 100%

    .infoWrap
      padding-left: floor(percentage(144 / 1440))
      padding-right: floor(percentage(144 / 1440))
      padding-bottom: 80px

    .infoInner
      clearfix()
      display: flex
      justify-content: space-between
      flex-wrap: wrap
    .underLine
      background: url('/img/dotline.png') 0 100% repeat-x
      margin-bottom: 62px
      width: 100%
      height: 1px

    .infoArea
      width: floor(percentage(564 / 1154))
      clearfix()

      // &:nth-child(2n-1)
      //   float: left

      // &:nth-child(2n)
      //   float: right

      .left
        width: floor(percentage(166 / 564))
        float: left
        position: relative
        margin-bottom: 62px

        img
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
          width: 100%
          height: auto

        .no
          position: absolute
          top: 7px
          left: 7px
          width: 34px
          height: 34px
          background-color: #dec470
          border-radius: 34px
          display: block

          img
            height: 11px
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            margin: auto

      .right
        width: floor(percentage(354 / 564))
        float: right
        margin-bottom: 62px

        .tit
          font-size: 15px
          line-height: 1.77
          margin-bottom: 15px
          text-transform: uppercase
          letter-spacing: 3.5px

        .txt
          font-size: 13.5px
          line-height: 1.77


  .musicWrap

    .contentsBody
      padding-bottom: 1px

    .readcopyArea
      position: absolute
      top: -138px
      left: 50%
      margin-left: floor(percentage(51 / 1440))
      width: floor(percentage(507 / 1440))
      padding: 66px floor(percentage(81 / 1440))
      background-color: #000
      box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
      box-sizing: border-box
      z-index: 3

      p
        font-size: 13.5px
        line-height: 1.77

    .imgArea
      margin-right: floor(percentage(440 / 1440))
      margin-bottom: 200px

      img
        width: 100%
        height: auto
        box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

    .scriptArea
      padding-bottom: 160px

      .kv
        width: 100%
        height: 600px
        background: url('/img/music_photo_02.jpg') 50% 50% no-repeat
        // background-size: 100% auto
        background-size: cover

      .titWrap
        margin-bottom: 80px
        margin-top: -92px
        padding-left: floor(percentage(262 / 1440))

        .svg
          width: auto
          height: 175px
          display: block

          svg
            width: 384.19px
            height: 100%
      .titWrap.en
        .svg
          svg
            width: 351px



      .scriptWrap
        padding-left: floor(percentage(262 / 1440))

        .svg
          width: 590px
          height: 1044px
          display: block

          svg
            width: 100%
            height: auto
      .scriptWrap.en
        .svg
          height: 1537px


      .img01
        width: 328px
        position: absolute
        top: 520px
        right: floor(percentage(142 / 1440))

        img
          width: 100%
          height: auto
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

      .img02
        width: 328px
        position: absolute
        top: 1320px
        right: floor(percentage(142 / 1440))

        img
          width: 100%
          height: auto
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

    .middleHeadArea
      width: 100%
      height: 525px
      position: relative
      display: table
      background: url('/img/music_photo_05.jpg') 50% 50% no-repeat
      background-size: cover

      .tit
        display: table-cell
        vertical-align: middle
        text-align: center

        .txt
          display: block
          position: absolute
          left: -9999px

        .svg
          width: auto
          height: 23.5px
          display: inline-block

          svg
            width: auto
            height: 100%

    .discographyArea
      padding-left: floor(percentage(52 / 1440))
      padding-right: floor(percentage(52 / 1440))
      // padding-bottom: 23px
      box-sizing: border-box
      background-color: #00000010

      .discographyInner
        background-color: #333333
        box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
        position: relative
        top: -52px
        padding-top: 228px
        padding-bottom: 228px

      .txt01
        font-size: 13.5px
        line-height: 1.77
        font-weight: bold
        padding-left: floor(percentage(800 / 1390))
        padding-right: floor(percentage(142 / 1390))

        &:after
          content: ''
          display: block
          width: 24px
          height: 1px
          opacity: 0.5
          margin: 46px 0 32px 0
          background-color: #fff

      .txt02
        font-size: 13.5px
        line-height: 1.77
        padding-left: floor(percentage(800 / 1390))
        padding-right: floor(percentage(142 / 1390))

      .img01
        width: floor(percentage(393 / 1390))
        position: absolute
        top: 228px
        left: floor(percentage(210 / 1390))

        img
          width: 100%
          height: auto
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
      .btn:hover
        .txt
          fadeOpacity(0, 1, music_hover)
          yanim(5, 0, music_hover)
          $delay = 0.1
          $step = 0.025
          for $i in 1...15
            span:nth-of-type({$i})
              display:inline-block
              $fade = fadeOpacity_music_hover 1.0s ease ($delay+$step*$i)s 1 normal forwards
              $yanim = yanim_music_hover 1.0s ease ($delay+$step*$i)s 1 normal forwards
              animationParamsArray(($fade $yanim))
        .bg
          circle
            rotateanim(0, 180, "play_hover")
            svgOffset(585, 0, "play_hover")
            $rotate = rotate_play_hover 1.0s ease 0s 1 normal forwards
            $offset = offset_play_hover 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
            animationParamsArray(($rotate $offset))
      .btn
        width: 186px
        height: 186px
        position: absolute
        top: -25px
        right: 55px
        display: block
        cursor: pointer

        .underline
          position: absolute
          top: 112px
          left: 50%
          width: 146px
          height: 1px
          background-color: #fff
          margin-left: -73px

        .txt
          position: absolute
          top: 50%
          left: 0
          width: 100%
          height: 1em
          margin-top: -0.5em
          display: block
          color: #fff
          text-align: center
          font-size: 12.5px
          line-height: 1.231
          fadeOpacity(0, 1, music)
          yanim(5, 0, music)
          $delay = 0.1
          $step = 0.025
          for $i in 1...15
            span:nth-of-type({$i})
              display:inline-block
              $fade = fadeOpacity_music 1.0s ease ($delay+$step*$i)s 1 normal forwards
              $yanim = yanim_music 1.0s ease ($delay+$step*$i)s 1 normal forwards
              animationParamsArray(($fade $yanim))

        .bg
          position: absolute
          top: 0
          left: 0
          z-index: 1

          circle
            fill: transparent
            stroke: #fff
            stroke-width: 0.5
            stroke-dasharray: 585 585
            transform-origin: center
            rotateanim(0, 180, "play")
            svgOffset(585, 0, "play")
            $rotate = rotate_play 1.0s ease 0s 1 normal forwards
            $offset = offset_play 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
            animationParamsArray(($rotate $offset))


  .locationWrap

    .contentsBody
      padding-bottom: 1px

    .readcopyArea
      position: absolute
      top: -138px
      left: 50%
      margin-left: floor(percentage(51 / 1440))
      width: floor(percentage(507 / 1440))
      padding: 66px floor(percentage(81 / 1440))
      background-color: #000
      box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
      box-sizing: border-box
      z-index: 3

      p
        font-size: 13.5px
        line-height: 1.77

    .mapArea
      height: 828px
      padding-left: floor(percentage(52 / 1440))
      padding-right: floor(percentage(52 / 1440))
      box-sizing: border-box
      background-color: #00000010
      margin-bottom: 80px

      .mapInner
        width: 100%
        height: 852px
        background-color: #aaa
        top: -52px
        z-index: 2
        box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

        #map
          width: 100%
          height: 100%

    .infoWrap
      padding-left: floor(percentage(144 / 1440))
      padding-right: floor(percentage(144 / 1440))
      padding-bottom: 80px

    .infoInner
      display: flex
      justify-content: space-between
      flex-wrap: wrap

    .underLine
      background: url('/img/dotline.png') 0 100% repeat-x
      margin-bottom: 62px
      width: 100%
      height: 1px

    .infoArea
      width: floor(percentage(564 / 1154))

      .left
        width: floor(percentage(166 / 564))
        float: left
        position: relative
        margin-bottom: 62px

        img
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
          width: 100%
          height: auto

        .no
          position: absolute
          top: 7px
          left: 7px
          width: 34px
          height: 34px
          background-color: #dec470
          border-radius: 34px
          display: block

          img
            height: 11px
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            margin: auto

      .right
        width: floor(percentage(354 / 564))
        float: right
        margin-bottom: 62px

        .tit
          font-size: 15px
          line-height: 1.77
          margin-bottom: 15px
          text-transform: uppercase
          letter-spacing: 3.5px

        .txt
          font-size: 13.5px
          line-height: 1.77


  .worksWrap

    .contentsBody
      padding-bottom: 1px

      .worksList
        clearfix()
        padding-left: floor(percentage(144 / 1440))
        padding-right: floor(percentage(144 / 1440))
        padding-bottom: 160px

      .list
        float: left
        width: floor(percentage(327 / 1154))
        text-align: center
        transform-style: preserve-3d
        perspective: 700

        img
          pointer-events: none

        &:nth-child(3n-1),
        &:nth-child(3n-2)
          // margin-right: floor(percentage(83 / 1154))
          margin-right: 8%

        .no
          font-size: 11px
          line-height: 1.231
          display: block
          margin: 0 auto

          &:after
            display: block
            content: ''
            width: 10px
            height: 1px
            transform: matrix3d(0.87,-0.5,0.00,0,0.50,0.87,0.00,0,0,0,1,0,0,0,0,1)
            margin: 12px auto 19px auto
            opacity: 0.5
        .tit.twoline
          height: 30px
        .tit
          height: 15px
          display: block
          width: 100%
          margin-bottom: 40px

          img
            width: auto
            height: 100%
            display: block
            margin: 0 auto

        .credit
          display: block
          margin: 0 auto
          font-size: 11px
          line-height: 1.231

        &.item01
          margin-top: -180px

        &.item02
          margin-top: -80px

        &.item03
          margin-top: 26px

        &.item01, &.item02, &.item03
          margin-bottom: 100px

          a
            color: #fff
            display: block
            width: 100%
            // height: 390px


          .thumb
            width: 100%
            height: auto
            box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
            margin-bottom: 63px
          .no
            &:after
              background-color: #fff

        &.item-no-video
          margin-bottom: 84px
          .back-rect
            position: absolute
            top: 0
            left: 0
            width: 100%
            height 100%
            box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
            background-color: #fff
            backface-visibility: hidden

          a
            color: #000
            width: 100%
            display: block
            position: relative

            &:before
              content:""
              display: block
              padding-top: 100%

          .inner
            position: absolute
            top: 50%
            left: 0
            right: 0
            margin-top: -56.5px
          .inner.twoline
            margin-top: -64px

          .no
            &:after
              background-color: #000


  .aboutWrap

    .contentsBody
      padding-bottom: 1px

    .readcopyArea
      position: absolute
      top: -138px
      left: 50%
      margin-left: floor(percentage(51 / 1440))
      width: floor(percentage(507 / 1440))
      padding: 66px floor(percentage(81 / 1440))
      background-color: #000
      box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
      box-sizing: border-box

      p
        font-size: 13.5px
        line-height: 1.77

    .contentsArea
      position7: relative
      box-sizing: border-box

      .no
        width: auto
        height: 185px
        position: absolute

        svg
          width: auto
          height: 100%

      .tit
        font-size: 15px
        line-height: 1.77
        margin-bottom: 35px
        text-transform: uppercase
        letter-spacing: 3.5px

      .txt
        font-size: 13.5px
        line-height: 1.77

      .line
        position: absolute
        top: 100%
        left: 50%
        width: 1px
        height: 131px
        background-color: #fff
        margin-top: -131px
        opacity: 0.5

      &.item01
        padding-top: 280px
        padding-right: floor(percentage(824 / 1440))
        padding-bottom: 204px
        padding-left: floor(percentage(262 / 1440))

        .no
          top: 164px
          left: - floor(percentage(85 / 1440))

      &.item02
        width: 100%
        height: 491px
        overflow: hidden

        .photo
          width: 633px
          height: 396px
          background-color: #000
          box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)

          img
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            margin: auto
            width: 100%
            height: auto

          &:nth-child(1)
            position: absolute
            bottom: 0
            left: 50%
            margin-left: -1054px

          &:nth-child(2)
            position: absolute
            top: 48px
            left: 50%
            margin-left: -316px

          &:nth-child(3)
            position: absolute
            top: 0
            left: 50%
            margin-left: 421px

      &.item03
        padding-top: 280px
        padding-right: floor(percentage(352 / 1440))
        padding-bottom: 204px
        padding-left: floor(percentage(734 / 1440))
        z-index: 2

        .no
          top: 164px
          left: floor(percentage(380 / 1440))

      &.item04
        padding-top: 158px
        padding-right: floor(percentage(24 / 1440))
        padding-bottom: 158px
        padding-left: floor(percentage(24 / 1440))
        background-color: #000
        z-index: 1
        margin-top: -32px

        img
          width: 100%
          height: auto

      &.item05
        padding-top: 122px
        padding-right: floor(percentage(588 / 1440))
        padding-bottom: 178px
        padding-left: floor(percentage(498 / 1440))
        z-index: 2
        margin-top: -228px
        margin-bottom: 92px

        .no
          top: 0
          left: floor(percentage(144 / 1440))

      &.item06

        img
          width: 100%
          height: auto

        .img:hover
          .photo
            filter: brightness(0.8)
          .txt
            fadeOpacity(0, 1, play_hover)
            yanim(5, 0, play_hover)
            $delay = 0.1
            $step = 0.025
            for $i in 1...10
              span:nth-of-type({$i})
                $fade = fadeOpacity_play_hover 1.0s ease ($delay+$step*$i)s 1 normal forwards
                $yanim = yanim_play_hover 1.0s ease ($delay+$step*$i)s 1 normal forwards
                animationParamsArray(($fade $yanim))

          .btn
            .bg
              circle
                rotateanim(0, 180, "play_hover")
                svgOffset(585, 0, "play_hover")
                $rotate = rotate_play_hover 1.0s ease 0s 1 normal forwards
                $offset = offset_play_hover 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
                animationParamsArray(($rotate $offset))

        .img
          position: relative
          margin-right: floor(percentage(144 / 1440))
          margin-left: floor(percentage(144 / 1440))
          display: block

          .photo
            padding-top: 80px
            padding-bottom: 80px
            background-color: #000
            margin-bottom: 113px
            box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2)
            transition: filter 0.3s ease 0s

        .btn
          width: 186px
          height: 186px
          position: absolute
          bottom: 25px
          right: 25px
          display: block
          cursor: pointer

          .underline
            position: absolute
            top: 112px
            left: 50%
            width: 146px
            height: 1px
            background-color: #fff
            margin-left: -73px

          .txt
            position: absolute
            top: 50%
            left: 0
            width: 100%
            height: 1em
            margin-top: -0.5em
            display: block
            color: #fff
            text-align: center
            font-size: 12.5px
            line-height: 1.231
            fadeOpacity(0, 1, play)
            yanim(5, 0, play)
            $delay = 0.1
            $step = 0.025
            for $i in 1...10
              span:nth-of-type({$i})
                $fade = fadeOpacity_play 1.0s ease ($delay+$step*$i)s 1 normal forwards
                $yanim = yanim_play 1.0s ease ($delay+$step*$i)s 1 normal forwards
                animationParamsArray(($fade $yanim))
            span
              display: inline-block

          .bg
            position: absolute
            top: 0
            left: 0
            z-index: 1

            circle
              fill: transparent
              stroke: #fff
              stroke-width: 0.5
              stroke-dasharray: 585 585
              transform-origin: center
              rotateanim(0, 180, "play")
              svgOffset(585, 0, "play")
              $rotate = rotate_play 1.0s ease 0s 1 normal forwards
              $offset = offset_play 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
              animationParamsArray(($rotate $offset))


body
  .back
    transform: matrix3d(0,1,0.00,0,-1.00,0,0.00,0,0,0,1,0,0,-60,0,1)
    transform-origin: left bottom 0px
    display: block
    width: 120px
    height: 57px
    position: fixed
    top: calc(50vh - 60px)
    left: 0
    z-index: 5

    .txt
      font-size: 11px
      line-height: 1.231
      display: block
      position: relative
      text-align: center
      color: #fff

    .line
      height: 32px
      width: 1px
      background-color: #fff
      position: absolute
      bottom: 0
      left: 50%
      display: block
