html,
body {
  border: 0;
  color: #000;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
html li,
body li {
  list-style-type: none;
}
* {
  box-sizing: border-box;
}
div,
span,
object,
iframe,
img,
table,
caption,
thead,
tbody,
tfoot,
tr,
td,
article,
aside,
canvas,
details,
figure,
hgroup,
menu,
nav,
footer,
header,
section,
summary,
mark,
audio,
video {
  border: 0;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cit,
code,
del,
dfn,
em,
ins,
q,
samp,
small,
strong,
sub,
sup,
b,
i,
hr,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
legend,
label {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}
article,
aside,
canvas,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
audio,
video {
  display: block;
}
table {
  border-collapse: separate;
  border-spacing: 0;
}
table caption,
table th,
table td {
  text-align: left;
  vertical-align: middle;
}
a img {
  border: 0;
}
a {
  color: #000;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
:focus {
  outline: 0;
}
span.upper {
  font-size: 10px;
  vertical-align: super;
}
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
table,
img,
section,
header,
footer,
article,
object,
svg {
  position: relative;
  backface-visibility: visible;
  outline: none;
}
img,
canvas,
object,
svg {
  display: block;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}
hr {
  display: none;
}
svg {
  overflow: visible;
  padding: 1px;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: none;
}
@-moz-keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@-webkit-keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@-o-keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@keyframes blurAnim {
  0% {
    filter: none;
  }
  60% {
    filter: blur(4px);
  }
  100% {
    filter: none;
  }
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/Roboto/Roboto-Thin.ttf") format('truetype');
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/Roboto/Roboto-Light.ttf") format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/Roboto/Roboto-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/YakuHanJP/YakuHanJP-Light.woff2") format("woff2"), url("/fonts/YakuHanJP/YakuHanJP-Light.woff") format("woff"), url("/fonts/YakuHanJP/YakuHanJP-Light.eot");
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("/fonts/YakuHanJP/YakuHanJP-Regular.woff") format("woff"), url("/fonts/YakuHanJP/YakuHanJP-Regular.eot");
}
@font-face {
  font-family: 'YakuHanJP';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff"), url("/fonts/YakuHanJP/YakuHanJP-Bold.eot");
}
@font-face {
  font-family: 'NotoSansJP';
  font-style: normal;
  font-weight: 200;
  src: url("/fonts/NotoSansJP/NotoSansJPLight.eot");
  src: url("/fonts/NotoSansJP/NotoSansJPLight.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPLight.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPLight.otf") format("opentype");
}
@font-face {
  font-family: 'NotoSansJP';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/NotoSansJP/NotoSansJPRegular.eot");
  src: url("/fonts/NotoSansJP/NotoSansJPRegular.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPRegular.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPRegular.otf") format("opentype");
}
@font-face {
  font-family: 'NotoSansJP';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/NotoSansJP/NotoSansJPBold.eot");
  src: url("/fonts/NotoSansJP/NotoSansJPBold.eot?#iefix") format("embedded-opentype"), url("/fonts/NotoSansJP/NotoSansJPBold.woff") format("woff"), url("/fonts/NotoSansJP/NotoSansJPBold.otf") format("opentype");
}
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Libre Baskerville Bold';
  font-style: bold;
  font-weight: 600;
  src: url("/fonts/Libre_Baskerville/LibreBaskerville-Bold.ttf") format('truetype');
}
body,
html {
  min-width: 995px;
  min-height: 600px;
  font-family: 'Roboto', 'YakuHanJP', 'NotoSansJP', sans-serif;
  font-weight: 100;
}
html {
  overflow-y: scroll;
}
body {
  position: relative;
  outline: none;
  color: #000;
  background: #fff;
  text-align: left;
  line-height: 1.231;
  -webkit-text-size-adjust: none;
  letter-spacing: 2.5px;
}
.blur-anim-text:hover {
  animation-name: blurAnim;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.3, 0.1, 0.2, 1);
  animation-iteration-count: 1;
}
.canvasMovieWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  color: #fff;
  background-size: cover;
  background-position: center center;
}
#VideoCanvasContainer img {
  height: 100vh;
}
.page-top {
  user-select: none;
  touch-callout: none;
}
#cursor.normal {
  opacity: 1;
}
#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 9999999;
  opacity: 1;
}
#loading .line {
  width: 0%;
  height: 1px;
  background-color: #ccc;
  top: calc(50% - 1px);
}
#loading .line.auto {
  width: 50%;
  animation-name: widthanimloading;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
}
@-moz-keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@-webkit-keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@-o-keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
@keyframes widthanimloading {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}
body.no-gl #wrap .enterWrap {
  background-color: #fff;
}
body.no-gl #wrap .enterWrap .enter {
  display: none;
}
body.no-gl #wrap .enterWrap .logo {
  margin-top: -294px;
}
body.no-gl #wrap .enterWrap .read {
  margin-top: 55px;
}
body.no-gl #wrap .enterWrap nav.nav {
  width: 519px;
  height: 44px;
  border-top: 2px solid #ccc;
  border-bottom: 2px solid #ccc;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -259.5px;
  margin-top: 167px;
  padding: 0 50px 0 48px;
}
body.no-gl #wrap .enterWrap nav.nav ul li {
  display: inline-block;
  line-height: 42px;
  font-size: -1;
  letter-spacing: 0em;
  font-weight: 400;
}
body.no-gl #wrap .enterWrap nav.nav ul li a {
  letter-spacing: 2.5px;
  color: #000;
}
body.no-gl #wrap .enterWrap nav.nav ul li:nth-child(n+2) {
  margin-left: 38px;
}
body.no-gl #wrap .enterWrap .nogl-description {
  display: block;
  position: absolute;
  height: 55px;
  width: 100%;
  background: #e5e5e5;
  bottom: 0;
  text-align: center;
}
body.no-gl #wrap .enterWrap .nogl-description p {
  font-size: 13px;
  line-height: 55px;
  color: #888;
}
.sp {
  display: none !important;
}
#wrap .chapter a {
  color: #000;
}
#wrap .chapter .contentsWrap .nav li div {
  color: #000;
}
#wrap .chapter .contentsWrap .nav li a {
  color: #000;
}
#wrap .chapter .contentsWrap .sound .equalizer span {
  background-color: #000;
}
#wrap .chapter .contentsWrap .sound .bg circle {
  stroke: #000;
}
#wrap .chapter .contentsWrap .switch .txt span {
  background-color: #000;
}
#wrap .chapter .contentsWrap .switch .bg circle {
  stroke: #000;
}
#wrap .chapter .contentsWrap .sns li a {
  color: #000;
}
#wrap .chapter .contentsWrap .other li a {
  color: #000;
}
#wrap .chapter .contentsWrap h1.logo path {
  fill: #000;
}
#wrap {
  position: relative;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  color: #fff;
}
#wrap a {
  color: #fff;
}
#wrap .enterWrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
}
#wrap .enterWrap .nogl-description {
  display: none;
}
#wrap .enterWrap nav.nav {
  display: none;
}
#wrap .enterWrap .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -204px;
  margin-left: -221px;
  width: 440px;
  height: 272px;
  text-indent: -9999px;
  position: relative;
  letter-spacing: 0;
  line-height: 0;
}
#wrap .enterWrap .logo svg {
  width: 100%;
  height: 100%;
}
#wrap .enterWrap .logo svg path {
  fill: #333;
}
#wrap .enterWrap .read {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 145px;
  margin-left: -134px;
  width: 268px;
  height: 10px;
  font-size: 13px;
  line-height: 1.231;
  text-align: center;
  letter-spacing: 1px;
  color: #000;
}
#wrap .enterWrap .read:before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 44px;
  height: 1px;
  background-color: #000;
  display: block;
}
#wrap .enterWrap .read:after {
  content: "";
  position: absolute;
  top: 0.5em;
  right: 0;
  width: 44px;
  height: 1px;
  background-color: #000;
  display: block;
}
#wrap .enterWrap .enter:hover .bg circle {
  fill: #fff;
}
#wrap .enterWrap .enter:hover .txt {
  color: #000;
}
#wrap .enterWrap .enter {
  position: absolute;
  bottom: 36px;
  left: 50%;
  margin-left: -48px;
  display: table;
  width: 96px;
  height: 96px;
  cursor: pointer;
}
#wrap .enterWrap .enter .txt {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  z-index: 2;
  font-weight: 100;
  transition: color 0.3s ease 0s;
}
#wrap .enterWrap .enter .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#wrap .enterWrap .enter .bg circle {
  fill: #000;
  stroke: #000;
  stroke-width: 1;
  stroke-dasharray: 303;
  stroke-dashoffset: 0;
  transform: rotateZ(-90deg);
  transform-origin: center;
  transition: fill 0.3s ease 0s;
}
#wrap .enterWrap .enter .bg circle.back {
  transition: all 0.5s linear 0s;
}
#wrap .contentsWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}
#wrap .contentsWrap .logo {
  position: absolute;
  top: 39px;
  left: 68px;
  width: 114px;
  height: 59px;
  z-index: 1000;
  text-indent: -9999px;
}
#wrap .contentsWrap .logo svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#wrap .contentsWrap .nav {
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: center;
}
#wrap .contentsWrap .nav ul {
  display: flex;
}
#wrap .contentsWrap .nav li {
  font-size: 14px;
  line-height: 1.231;
  font-weight: 100;
  margin-right: 52px;
}
#wrap .contentsWrap .nav li:first-child {
  margin-right: 58px;
}
#wrap .contentsWrap .nav li:nth-child(3) {
  margin-right: 60px;
}
#wrap .contentsWrap .nav li:last-child {
  margin-right: 0;
}
#wrap .contentsWrap .nav li div {
  color: #fff;
  cursor: default;
}
#wrap .contentsWrap .nav li a {
  color: #fff;
}
#wrap .contentsWrap .sound {
  position: absolute;
  top: 52px;
  right: 70px;
  z-index: 100;
  width: 32px;
  height: 32px;
  display: block;
  cursor: pointer;
}
#wrap .contentsWrap .sound .equalizer {
  position: relative;
  z-index: 2;
  width: 32px;
  height: 32px;
  display: block;
}
#wrap .contentsWrap .sound .equalizer span {
  width: 1px;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 12px;
}
@-moz-keyframes heightanim-equalizer {
  from {
    height: 1px;
  }
  to {
    height: 8px;
  }
}
@-webkit-keyframes heightanim-equalizer {
  from {
    height: 1px;
  }
  to {
    height: 8px;
  }
}
@-o-keyframes heightanim-equalizer {
  from {
    height: 1px;
  }
  to {
    height: 8px;
  }
}
@keyframes heightanim-equalizer {
  from {
    height: 1px;
  }
  to {
    height: 8px;
  }
}
#wrap .contentsWrap .sound .equalizer span:nth-of-type(1) {
  left: 9px;
  animation-name: heightanim-equalizer;
  animation-duration: 0.32s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
}
#wrap .contentsWrap .sound .equalizer span:nth-of-type(2) {
  left: 12px;
  animation-name: heightanim-equalizer;
  animation-duration: 0.32s;
  animation-timing-function: linear;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
}
#wrap .contentsWrap .sound .equalizer span:nth-of-type(3) {
  left: 15px;
  animation-name: heightanim-equalizer;
  animation-duration: 0.32s;
  animation-timing-function: linear;
  animation-delay: 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
}
#wrap .contentsWrap .sound .equalizer span:nth-of-type(4) {
  left: 18px;
  animation-name: heightanim-equalizer;
  animation-duration: 0.32s;
  animation-timing-function: linear;
  animation-delay: 0.6s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
}
#wrap .contentsWrap .sound .equalizer span:nth-of-type(5) {
  left: 21px;
  animation-name: heightanim-equalizer;
  animation-duration: 0.32s;
  animation-timing-function: linear;
  animation-delay: 0.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: none;
  animation-play-state: running;
}
#wrap .contentsWrap .sound .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform-origin: center;
  transition: transform 0.2s ease 0.2s;
}
#wrap .contentsWrap .sound .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 377 377;
  transform-origin: center;
}
#wrap .contentsWrap .sound:hover .bg {
  transform: scale(1.2);
}
#wrap .contentsWrap .sound:hover .bg circle {
  animation-name: rotate_sound, offset_sound;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_sound {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@-webkit-keyframes rotate_sound {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@-o-keyframes rotate_sound {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@keyframes rotate_sound {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@-moz-keyframes offset_sound {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
@-webkit-keyframes offset_sound {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
@-o-keyframes offset_sound {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
@keyframes offset_sound {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
#wrap .contentsWrap .sound.muted .equalizer span {
  animation: none;
}
#wrap .contentsWrap .other {
  position: absolute;
  z-index: 100;
  bottom: 63px;
  left: 70px;
}
#wrap .contentsWrap .other li {
  font-size: 11px;
  line-height: 1.231;
  font-weight: 100;
}
#wrap .contentsWrap .other li a {
  color: #fff;
}
#wrap .contentsWrap .sns {
  position: absolute;
  z-index: 100;
  bottom: 63px;
  right: 70px;
}
#wrap .contentsWrap .sns li {
  font-size: 11px;
  line-height: 1.231;
  font-weight: 100;
}
#wrap .contentsWrap .sns li a {
  color: #fff;
}
@-moz-keyframes fadeOpacity_switch_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_switch_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_switch_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_switch_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#wrap .contentsWrap .switch:hover .txt span:nth-of-type(1) {
  animation-name: fadeOpacity_switch_hover;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
#wrap .contentsWrap .switch:hover .txt span:nth-of-type(2) {
  animation-name: fadeOpacity_switch_hover;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.15s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
#wrap .contentsWrap .switch:hover .txt span:nth-of-type(3) {
  animation-name: fadeOpacity_switch_hover;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
#wrap .contentsWrap .switch:hover .bg {
  transform: scale(1.2);
}
#wrap .contentsWrap .switch:hover .bg circle {
  animation-name: rotate_switch, offset_switch;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_switch {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@-webkit-keyframes rotate_switch {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@-o-keyframes rotate_switch {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@keyframes rotate_switch {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
}
@-moz-keyframes offset_switch {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
@-webkit-keyframes offset_switch {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
@-o-keyframes offset_switch {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
@keyframes offset_switch {
  from {
    stroke-dashoffset: 377;
  }
  to {
    stroke-dashoffset: 172;
  }
}
#wrap .contentsWrap .switch {
  width: 66px;
  height: 66px;
  position: absolute;
  left: 50%;
  bottom: 44px;
  margin-left: -33px;
  z-index: 100;
}
#wrap .contentsWrap .switch a {
  display: block;
  width: 66px;
  height: 66px;
  position: relative;
  cursor: pointer;
}
#wrap .contentsWrap .switch .txt {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  z-index: 2;
  width: 66px;
  height: 66px;
}
#wrap .contentsWrap .switch .txt span {
  position: absolute;
  top: 31px;
  background-color: #fff;
  width: 4px;
  height: 4px;
}
@-moz-keyframes fadeOpacity_switch {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_switch {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_switch {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_switch {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#wrap .contentsWrap .switch .txt span:nth-of-type(1) {
  animation-name: fadeOpacity_switch;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  left: 23px;
}
#wrap .contentsWrap .switch .txt span:nth-of-type(2) {
  animation-name: fadeOpacity_switch;
  animation-duration: 1.5s;
  animation-timing-function: ease;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  left: 30px;
}
#wrap .contentsWrap .switch .txt span:nth-of-type(3) {
  animation-name: fadeOpacity_switch;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  left: 38px;
}
#wrap .contentsWrap .switch .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.2s ease 0.2s;
  transform-origin: center;
}
#wrap .contentsWrap .switch .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 377 377;
  stroke: #fff;
  transform-origin: center;
}
#wrap .contentsWrap .seek {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  z-index: 10;
  pointer-events: none;
}
#wrap .contentsWrap .seek .seekbar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.188);
  width: 100%;
  height: 1px;
}
#wrap .contentsWrap .seek .line {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 30%;
  height: 1px;
}
#wrap .contentsWrap .seek .current {
  position: absolute;
  top: -3px;
  left: 30%;
  background-color: #fff;
  width: 1px;
  height: 7px;
}
#wrap .contentsWrap .movieArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  perspective: 500;
  -webkit-perspective: 500;
  opacity: 0;
}
#wrap .contentsWrap .movieArea .movieInner {
  position: absolute;
  width: 100%;
  height: 100%;
}
#wrap .contentsWrap .movieArea .item {
  position: relative;
}
#wrap .contentsWrap .movieArea .item .embed {
  background-color: #ccc;
  position: absolute;
}
#wrap .contentsWrap .movieArea .item .name {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
#wrap .contentsWrap .movieArea .item .name .tit {
  position: absolute;
  bottom: 77px;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  font-size: 13px;
  color: #fff;
  line-height: 1.231;
  letter-spacing: 5px;
}
#wrap .contentsWrap .movieArea .item .name .tit a {
  color: #fff;
}
#wrap .contentsWrap .movieArea .item .name .chapter {
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  font-size: 11px;
  color: rgba(255,255,255,0.188);
  line-height: 1.231;
}
#wrap .contentsWrap .movieArea .item .state {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}
#wrap .contentsWrap .movieArea .item .state .neWrap {
  top: 40px;
  position: relative;
  left: 50%;
  float: left;
  margin-bottom: 20px;
  background-size: auto 100%;
}
#wrap .contentsWrap .movieArea .item .state .neWrap::after {
  content: "";
  clear: both;
  display: block;
}
#wrap .contentsWrap .movieArea .item .state .e {
  float: left;
  position: relative;
  left: -50%;
  font-size: 10px;
  color: rgba(255,255,255,0.188);
  line-height: 1.231;
  float: left;
  margin-right: 8px;
}
#wrap .contentsWrap .movieArea .item .state .e:after {
  content: '/';
  margin-left: 8px;
}
#wrap .contentsWrap .movieArea .item .state .n {
  float: left;
  position: relative;
  left: -50%;
  font-size: 10px;
  color: rgba(255,255,255,0.188);
  line-height: 1.231;
  float: right;
}
#wrap .contentsWrap .movieArea .item .state .location {
  position: absolute;
  top: 70px;
  left: 50%;
  clear: both;
  font-size: 13px;
  color: #fff;
  line-height: 1.231;
  letter-spacing: 3px;
  text-align: center;
  width: 300px;
  height: 19px;
  margin-left: -150px;
  padding-bottom: 5px;
}
#wrap .contentsWrap .movieArea .item .state .location a {
  color: #fff;
}
#wrap .contentsWrap .movieArea .item .state .location .icon {
  display: inline-block;
  width: 24px;
  height: 19px;
  background: url("/img/icon_01.png") no-repeat 0 0;
  background-size: auto 19px;
  margin-bottom: -5px;
}
#wrap .contentsWrap .movieArea.view {
  z-index: 2;
  color: #ddd;
}
#wrap .contentsWrap .movieArea.view .moveInner {
  top: 50%;
  left: 50%;
  margin-left: -50%;
  margin-top: -50%;
}
#wrap .contentsWrap .movieArea.view .item {
  width: 100%;
  height: 100%;
  position: absolute;
}
#wrap .contentsWrap .movieArea.thumb {
  z-index: 1;
}
#wrap .contentsWrap .movieArea.thumb .scroller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
#wrap .contentsWrap .movieArea.thumb .movieInner {
  z-index: 1;
  width: auto;
  height: 100%;
  white-space: nowrap;
  cursor: grab;
  font-size: 0;
  word-spacing: 0px;
  letter-spacing: 0px;
}
#wrap .contentsWrap .movieArea.thumb .movieInner::after {
  content: "";
  clear: both;
  display: block;
}
#wrap .contentsWrap .movieArea.thumb .item:first-child {
  margin-left: calc(50vw - 187.5px);
}
#wrap .contentsWrap .movieArea.thumb .item:last-child {
  margin-right: calc(50vw - 187.5px);
}
#wrap .contentsWrap .movieArea.thumb .item {
  width: 375px;
  height: 250px;
  margin-right: 35px;
  display: inline-block;
  letter-spacing: 2.5px;
  margin-top: calc(50vh - 125px);
  vertical-align: top;
}
#wrap .contentsWrap .movieArea.thumb .item .name {
  position: absolute;
  bottom: 0;
  top: calc(50% - 118px);
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .name .tit {
  position: relative;
  bottom: 0;
}
#wrap .contentsWrap .movieArea.thumb .item .name .tit a {
  color: rgba(0,0,0,0.188);
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .name .tit span {
  color: rgba(0,0,0,0.188);
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .name .chapter {
  opacity: 0;
  position: relative;
  bottom: 0;
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .state {
  position: absolute;
  top: calc(50% + 102px);
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .state .location {
  top: 0px;
  position: relative;
}
#wrap .contentsWrap .movieArea.thumb .item .state .location .icon {
  background-image: url("/img/icon_03.png");
  opacity: 0.3;
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .state .location a {
  color: rgba(0,0,0,0.188);
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .state .neWrap {
  opacity: 0;
  position: relative;
  margin-bottom: 0;
  top: 0;
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .state .neWrap .n {
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .state .neWrap .e {
  transition: all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1);
}
#wrap .contentsWrap .movieArea.thumb .item .embed {
  width: 100%;
  height: 158px;
  top: calc(50% - 79px);
  left: 0;
  transform-origin: center;
  background-color: rgba(0,0,0,0);
  z-index: 2;
  transition: all 0.4s cubic-bezier(0.2, 0.5, 0.3, 1);
  position: absolute;
  cursor: pointer;
}
#wrap .contentsWrap .movieArea.thumb .item:hover {
  height: 400px;
  margin-top: calc(50vh - 200px);
  z-index: 10 !important;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .embed {
  transform: scale(1.25) !important;
  transform-origin: center;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .name {
  transform: translate3d(0, -70px, 0);
}
#wrap .contentsWrap .movieArea.thumb .item:hover .name .tit a {
  color: #000;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .name .tit span {
  color: #000;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .name .chapter {
  margin-top: 20px;
  opacity: 1;
  color: #000;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .state {
  transform: translate3d(0, 40px, 0);
}
#wrap .contentsWrap .movieArea.thumb .item:hover .state .location .icon {
  opacity: 1;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .state .location a {
  color: #000;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .state .neWrap {
  margin-top: 20px;
  opacity: 1;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .state .neWrap .e {
  color: #000;
}
#wrap .contentsWrap .movieArea.thumb .item:hover .state .neWrap .n {
  color: #000;
}
#wrap .shareWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}
#wrap .shareWrap a {
  color: #000;
}
#wrap .shareWrap .linkWrap {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: -0.5em;
}
#wrap .shareWrap .linkWrap::after {
  content: "";
  clear: both;
  display: block;
}
#wrap .shareWrap .linkWrap .youtube,
#wrap .shareWrap .linkWrap .twitter,
#wrap .shareWrap .linkWrap .facebook {
  font-size: 21px;
  line-height: 1.231;
  letter-spacing: 5px;
  width: 33.333%;
  float: left;
  text-align: center;
}
#wrap .shareWrap .linkWrap .youtube a,
#wrap .shareWrap .linkWrap .twitter a,
#wrap .shareWrap .linkWrap .facebook a {
  font-family: 'Libre Baskerville', serif;
}
#wrap .creditWrap {
  position: relative;
  z-index: 1;
}
#spMenu {
  display: none;
}
.none {
  display: none;
}
.page-share .line,
.page-credit .line {
  background-color: #000;
}
.page-share #contents .close .line,
.page-credit #contents .close .line {
  background-color: #000;
}
.page-share #contents .close .txt .l,
.page-credit #contents .close .txt .l {
  background-color: #000;
}
.page-share #contents .close .txt .r,
.page-credit #contents .close .txt .r {
  background-color: #000;
}
.page-share #contents .close .bg circle,
.page-credit #contents .close .bg circle {
  stroke: #000;
}
.page-works.darkto {
  filter: brightness(0.1);
  transition: filter 0.6s ease 0s;
}
.page-works.darkfrom {
  filter: brightness(0.1);
  animation-name: brightness-works;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.1s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes brightness-works {
  from {
    filter: brightness(0.1);
  }
  to {
    filter: brightness(1);
  }
}
@-webkit-keyframes brightness-works {
  from {
    filter: brightness(0.1);
  }
  to {
    filter: brightness(1);
  }
}
@-o-keyframes brightness-works {
  from {
    filter: brightness(0.1);
  }
  to {
    filter: brightness(1);
  }
}
@keyframes brightness-works {
  from {
    filter: brightness(0.1);
  }
  to {
    filter: brightness(1);
  }
}
.close:hover {
  transform: scale(1.2) translate3d(0, 13px, 0);
}
.close:hover .txt {
  transform: rotateZ(180deg);
}
.close.run-enter .line {
  animation-name: heightanim2;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes heightanim2 {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
@-webkit-keyframes heightanim2 {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
@-o-keyframes heightanim2 {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
@keyframes heightanim2 {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
.close.run-enter .bg circle {
  animation-name: rotate_closecircle, offset_closecircle2;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-webkit-keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-o-keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
.close.run-leave .line {
  height: 38px;
  animation-name: heightanim;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.close.run-leave .bg circle {
  animation-name: rotate_closecircle, offset_closecircle2;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_closecircle {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-webkit-keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-o-keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes offset_closecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
.close {
  transition: transform 0.3s ease 0s;
}
.close .line {
  height: 0px;
  animation-name: heightanim;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes heightanim {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
@-webkit-keyframes heightanim {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
@-o-keyframes heightanim {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
@keyframes heightanim {
  from {
    height: 0px;
  }
  to {
    height: 38px;
  }
}
.close .txt {
  transition: transform 0.5s ease 0s;
  opacity: 0;
  animation-name: fadeOpacity_close;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-delay: 2.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes fadeOpacity_close {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_close {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_close {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_close {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.close .bg circle {
  stroke-dashoffset: 304;
  transform-origin: center;
  animation-name: rotate_closecircle1, offset_closecircle1;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 2s, 2.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_closecircle1 {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_closecircle1 {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_closecircle1 {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_closecircle1 {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_closecircle1 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-webkit-keyframes offset_closecircle1 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-o-keyframes offset_closecircle1 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes offset_closecircle1 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
.close {
  width: 65px;
  height: 65px;
  position: fixed;
  top: 40px;
  left: 50%;
  margin-left: -32.5px;
  display: block;
  cursor: pointer;
  z-index: 999;
}
.close .line {
  position: absolute;
  top: -27px;
  left: 50%;
  width: 1px;
  background-color: #fff;
}
.close .txt {
  position: relative;
  width: 65px;
  height: 65px;
  display: block;
  transform-origin: 33.5px center;
}
.close .txt .l {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -1px;
  width: 19px;
  height: 1px;
  background-color: #fff;
  transform: matrix3d(0.71, -0.71, 0, 0, 0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transform: matrix3d(0.71, -0.71, 0, 0, 0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
.close .txt .r {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -1px;
  width: 19px;
  height: 1px;
  background-color: #fff;
  transform: matrix3d(0.71, 0.71, 0, 0, -0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-transform: matrix3d(0.71, 0.71, 0, 0, -0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
}
.close .bg {
  position: absolute;
  top: 0;
  left: 1px;
  z-index: 1;
}
.close .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 304;
}
#contents .footerArea {
  background-color: #000;
  width: 100%;
  height: 248px;
}
#contents .footerArea .share:hover .arrow {
  transform: scale(1.2) translate3d(10px, 0, 0);
}
@-moz-keyframes fadeOpacity_share_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_share_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_share_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_share_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes yanim_share_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes yanim_share_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes yanim_share_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@keyframes yanim_share_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
#contents .footerArea .share:hover .txt span:nth-of-type(1) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.125s, 0.125s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(2) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.15s, 0.15s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(3) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.175s, 0.175s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(4) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.2s, 0.2s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(5) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.225s, 0.225s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(6) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.25s, 0.25s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(7) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.275s, 0.275s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(8) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.3s, 0.3s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share:hover .txt span:nth-of-type(9) {
  display: inline-block;
  animation-name: fadeOpacity_share_hover, yanim_share_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.325s, 0.325s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share.run-enter .arrow .line {
  animation-name: widthanim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
@-webkit-keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
@-o-keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
@keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
#contents .footerArea .share.run-enter .arrow .line2 {
  animation-name: leftanim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-webkit-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-o-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
#contents .footerArea .share.run-enter .arrow .line3 {
  animation-name: leftanim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-webkit-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-o-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
#contents .footerArea .share.run-enter .arrow svg circle {
  animation-name: rotate_sharecircle, offset_sharecircle2;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@-webkit-keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@-o-keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@-moz-keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-webkit-keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-o-keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
#contents .footerArea .share.run-leave .arrow .line {
  animation-name: widthanim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
@-webkit-keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
@-o-keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
@keyframes widthanim {
  from {
    width: 0px;
  }
  to {
    width: 115px;
  }
}
#contents .footerArea .share.run-leave .arrow .line2 {
  animation-name: leftanim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-webkit-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-o-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
#contents .footerArea .share.run-leave .arrow .line3 {
  animation-name: leftanim;
  animation-duration: 0.6s;
  animation-timing-function: ease;
  animation-delay: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
@-moz-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-webkit-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@-o-keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
@keyframes leftanim {
  from {
    left: 0px;
  }
  to {
    left: 112px;
  }
}
#contents .footerArea .share.run-leave .arrow svg circle {
  animation-name: rotate_sharecircle, offset_sharecircle2;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@-webkit-keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@-o-keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@keyframes rotate_sharecircle {
  0% {
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 270deg);
  }
}
@-moz-keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-webkit-keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@-o-keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
@keyframes offset_sharecircle2 {
  from {
    stroke-dashoffset: 304;
  }
  to {
    stroke-dashoffset: 100;
  }
}
#contents .footerArea .share .arrow {
  transition: transform 0.5s ease 0s;
  transform-origin: left center;
}
#contents .footerArea .share .arrow svg circle {
  transform-origin: center;
  stroke-dasharray: 304;
}
#contents .footerArea .share {
  display: table;
  color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
}
#contents .footerArea .share .txt {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 15px;
  line-height: 1.231;
  letter-spacing: 4px;
}
@-moz-keyframes fadeOpacity_share {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_share {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_share {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_share {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes yanim_share {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes yanim_share {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes yanim_share {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@keyframes yanim_share {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
#contents .footerArea .share .txt span:nth-of-type(1) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.125s, 0.125s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(2) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.15s, 0.15s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(3) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.175s, 0.175s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(4) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.2s, 0.2s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(5) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.225s, 0.225s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(6) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.25s, 0.25s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(7) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.275s, 0.275s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(8) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.3s, 0.3s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .txt span:nth-of-type(9) {
  display: inline-block;
  animation-name: fadeOpacity_share, yanim_share;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.325s, 0.325s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .footerArea .share .arrow {
  display: block;
  position: absolute;
  top: 50%;
  right: 70px;
  margin-top: -32.5px;
  width: 150px;
  height: 65px;
}
#contents .footerArea .share .arrow .line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 115px;
  height: 1px;
  background-color: #fff;
  opacity: 0.75;
}
#contents .footerArea .share .arrow .line2 {
  position: absolute;
  top: 50%;
  left: 112px;
  width: 3px;
  height: 1px;
  background-color: #fff;
  transform: matrix3d(0.71, 0.71, 0, 0, -0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  margin-top: -2px;
}
#contents .footerArea .share .arrow .line3 {
  position: absolute;
  top: 50%;
  left: 112px;
  width: 3px;
  height: 1px;
  background-color: #fff;
  transform: matrix3d(0.71, -0.71, 0, 0, 0.71, 0.71, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  margin-top: 1px;
}
#contents .footerArea .share .arrow .circle {
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 65px;
}
#contents .footerArea .share .arrow circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 377 377;
}
#contents .contentsBody {
  background: url("/img/bg_01.png") 0 0 repeat;
}
#contents .headerArea {
  width: 100%;
  height: 100vh;
  position: relative;
  display: table;
}
#contents .headerArea .tit {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#contents .headerArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .headerArea .tit .svg {
  width: auto;
  height: 23.5px;
  display: inline-block;
}
#contents .headerArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .creditWrap {
  padding-bottom: 260px;
}
#contents .creditWrap .tit {
  padding-top: 236px;
  margin-bottom: 120px;
  text-align: center;
}
#contents .creditWrap .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .creditWrap .tit .svg {
  width: auto;
  height: 22px;
  display: block;
}
#contents .creditWrap .tit .svg svg {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .creditWrap .stit {
  margin-bottom: 38px;
  margin-top: 100px;
  width: auto;
  height: 18px;
  text-align: center;
}
#contents .creditWrap .stit img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .creditWrap dl::after {
  content: "";
  clear: both;
  display: block;
}
#contents .creditWrap dl dt {
  width: 49%;
  padding-left: 25%;
  height: 36px;
  float: left;
  font-size: 12px;
  line-height: 1.231;
  color: #888;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: right;
  text-transform: uppercase;
  box-sizing: border-box;
}
#contents .creditWrap dl dt.h60 {
  height: 60px;
}
#contents .creditWrap dl dd {
  width: 49%;
  height: 36px;
  float: right;
  font-size: 15px;
  line-height: 1.231;
  color: #000;
}
#contents .creditWrap dl dd.h60 {
  height: 60px;
}
#contents .creditWrap dl dd .tm {
  font-size: 10px;
}
#contents .worksDetailWrap {
  background: url("/img/bg_01.png") 0 0 repeat;
}
#contents .worksDetailWrap.file001 .worksDetailHeader {
  background-image: url("/img/works_001_kv_01.jpg");
}
#contents .worksDetailWrap.file001 .memberArea .tit {
  background-image: url("/img/works_001_photo_04.jpg");
}
#contents .worksDetailWrap.file001 .groupphoto {
  background-image: url("/img/works_001_photo_05.jpg");
}
#contents .worksDetailWrap.file001 .locationArea {
  background-image: url("/img/works_001_photo_09.jpg");
}
#contents .worksDetailWrap.file001 .worksDetailBody .titWrap .svg svg {
  width: 257px;
}
#contents .worksDetailWrap.file001 .worksDetailBody .scriptWrap .svg svg {
  height: 1303.56px;
}
#contents .worksDetailWrap.file001 .worksDetailBody .titWrap .svg.en svg {
  width: 390px;
}
#contents .worksDetailWrap.file001 .worksDetailBody .scriptWrap .svg.en svg {
  height: 1563.23px;
}
#contents .worksDetailWrap.file001 .worksDetailHeader .no .svg svg {
  width: 593.11px;
}
#contents .worksDetailWrap.file002 .worksDetailHeader {
  background-image: url("/img/works_002_kv_01.jpg");
}
#contents .worksDetailWrap.file002 .memberArea .tit {
  background-image: url("/img/works_002_photo_04.jpg");
}
#contents .worksDetailWrap.file002 .groupphoto {
  background-image: url("/img/works_002_photo_05.jpg");
}
#contents .worksDetailWrap.file002 .locationArea {
  background-image: url("/img/works_002_photo_09.jpg");
}
#contents .worksDetailWrap.file002 .worksDetailBody .titWrap .svg svg {
  width: 370.56px;
}
#contents .worksDetailWrap.file002 .worksDetailBody .scriptWrap .svg svg {
  height: 2308.53px;
}
#contents .worksDetailWrap.file002 .worksDetailBody .titWrap .svg.en svg {
  width: 414.8px;
  height: 253.56px;
}
#contents .worksDetailWrap.file002 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2761.525px;
}
#contents .worksDetailWrap.file003 .worksDetailHeader {
  background-image: url("/img/works_003_kv_01.jpg");
}
#contents .worksDetailWrap.file003 .memberArea .tit {
  background-image: url("/img/works_003_photo_04.jpg");
}
#contents .worksDetailWrap.file003 .groupphoto {
  background-image: url("/img/works_003_photo_05.jpg");
}
#contents .worksDetailWrap.file003 .locationArea {
  background-image: url("/img/works_003_photo_09.jpg");
}
#contents .worksDetailWrap.file003 .worksDetailBody .titWrap .svg svg {
  width: 501.08px;
}
#contents .worksDetailWrap.file003 .worksDetailBody .scriptWrap .svg svg {
  height: 2150px;
}
#contents .worksDetailWrap.file003 .worksDetailBody .titWrap .svg.en svg {
  width: 452px;
}
#contents .worksDetailWrap.file003 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2413px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .titWrap .svg svg {
  width: 359.75px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .scriptWrap .svg svg {
  height: 2872.36px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .titWrap .svg.en svg {
  width: 446.04px;
}
#contents .worksDetailWrap.file004 .worksDetailBody .scriptWrap .svg.en svg {
  height: 3397.985px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .titWrap .svg svg {
  width: 129.25px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .scriptWrap .svg svg {
  height: 1958.56px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .titWrap .svg.en svg {
  width: 328.645px;
}
#contents .worksDetailWrap.file005 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2373.01px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .titWrap .svg svg {
  width: 505.61px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .scriptWrap .svg svg {
  height: 2133.09px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .titWrap .svg.en {
  height: 250px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .titWrap .svg.en svg {
  width: 702.355px;
  height: 250px;
}
#contents .worksDetailWrap.file006 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2660.99px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .titWrap .svg svg {
  width: 178.89px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .scriptWrap .svg svg {
  height: 2125.92px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .titWrap .svg.en svg {
  width: 445.55px;
}
#contents .worksDetailWrap.file007 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2801.88px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .titWrap .svg svg {
  width: 205.16px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .scriptWrap .svg svg {
  height: 1486.08px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .titWrap .svg.en svg {
  width: 686.84px;
}
#contents .worksDetailWrap.file008 .worksDetailBody .scriptWrap .svg.en svg {
  height: 1598.14px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .titWrap .svg svg {
  width: 244.3px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .scriptWrap .svg svg {
  height: 1759.86px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .titWrap .svg.en svg {
  width: 273.93px;
}
#contents .worksDetailWrap.file009 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2055.58px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .titWrap .svg svg {
  width: 442.09px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .scriptWrap .svg svg {
  height: 1867.19px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .titWrap .svg.en svg {
  width: 453.535px;
}
#contents .worksDetailWrap.file010 .worksDetailBody .scriptWrap .svg.en svg {
  height: 2056.035px;
}
#contents .worksDetailWrap.file004 .worksDetailHeader,
#contents .worksDetailWrap.file005 .worksDetailHeader,
#contents .worksDetailWrap.file006 .worksDetailHeader,
#contents .worksDetailWrap.file007 .worksDetailHeader,
#contents .worksDetailWrap.file008 .worksDetailHeader,
#contents .worksDetailWrap.file009 .worksDetailHeader,
#contents .worksDetailWrap.file010 .worksDetailHeader {
  background-image: url("/img/works_004_kv_01.jpg");
}
#contents .worksDetailWrap .worksDetailHeader {
  width: 100%;
  height: 600px;
  position: relative;
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 3;
}
#contents .worksDetailWrap .worksDetailHeader .no {
  position: absolute;
  top: 0;
  left: 18%;
}
#contents .worksDetailWrap .worksDetailHeader .no .svg {
  display: block;
  width: auto;
  height: 213px;
}
#contents .worksDetailWrap .worksDetailHeader .no .svg svg {
  width: auto;
  height: 100%;
}
#contents .worksDetailWrap .worksDetailBody {
  min-height: 1745px;
  z-index: 4;
}
#contents .worksDetailWrap .worksDetailBody .writer {
  text-align: center;
  padding-bottom: 260px;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit {
  margin-top: 258px;
  margin-bottom: 62px;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit .svg {
  width: auto;
  height: 21px;
  display: block;
}
#contents .worksDetailWrap .worksDetailBody .writer .tit .svg svg {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .worksDetailWrap .worksDetailBody .writer .photo {
  padding-left: 26%;
  padding-right: 26%;
  margin-bottom: 52px;
}
#contents .worksDetailWrap .worksDetailBody .writer .photo img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .worksDetailBody .writer .job {
  font-size: 12.5px;
  line-height: 1.231;
  color: #888;
  margin-bottom: 17px;
  font-weight: 400;
}
#contents .worksDetailWrap .worksDetailBody .writer .name {
  height: 21px;
}
#contents .worksDetailWrap .worksDetailBody .writer .name img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
#contents .worksDetailWrap .worksDetailBody .titWrap {
  position: relative;
  margin-bottom: -12px;
  top: -92px;
  padding-left: 18%;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg {
  width: auto;
  display: block;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg svg {
  width: auto;
  height: 175px;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg svg path {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .titWrap .svg svg rect {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap {
  padding-left: 18%;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg {
  width: 590px;
  height: 100%;
  display: block;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg svg {
  width: 100%;
  height: auto;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg svg path {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .scriptWrap .svg svg rect {
  fill: #fff;
}
#contents .worksDetailWrap .worksDetailBody .img01 {
  width: 328px;
  position: absolute;
  top: -92px;
  right: 9%;
}
#contents .worksDetailWrap .worksDetailBody .img01 img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .worksDetailBody .img02 {
  width: 328px;
  position: absolute;
  top: 722px;
  right: 9%;
}
#contents .worksDetailWrap .worksDetailBody .img02 img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .worksDetailBody .img03 {
  width: 328px;
  position: absolute;
  top: 1526px;
  right: 9%;
}
#contents .worksDetailWrap .worksDetailBody .img03 img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .memberArea {
  margin-top: -325px;
  z-index: 1;
}
#contents .worksDetailWrap .memberArea .tit {
  width: 100%;
  height: 900px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#contents .worksDetailWrap .memberArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .worksDetailWrap .memberArea .tit .svg {
  width: 241.31px;
  height: 23.5px;
  display: block;
  position: absolute;
  top: 736px;
  left: 50%;
  margin-left: -115px;
}
#contents .worksDetailWrap .memberArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .worksDetailWrap .memberArea .groupphoto {
  width: 100%;
  height: 900px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 174px;
}
#contents .worksDetailWrap .memberArea .stit {
  display: block;
  height: 21px;
  margin-top: 156px;
  margin-bottom: 62px;
}
#contents .worksDetailWrap .memberArea .stit img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .worksDetailWrap .memberArea .portlate {
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 26px;
}
#contents .worksDetailWrap .memberArea .portlate::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .memberArea .portlate .left {
  width: 48%;
  float: left;
}
#contents .worksDetailWrap .memberArea .portlate .left img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .memberArea .portlate .right {
  width: 48%;
  float: right;
  padding-top: 32%;
  position: relative;
}
#contents .worksDetailWrap .memberArea .portlate .right .nameWrap {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50px;
  margin-top: -25px;
  text-align: center;
}
#contents .worksDetailWrap .memberArea .portlate .right .job {
  font-size: 12.5px;
  line-height: 1.231;
  color: #888;
  margin-bottom: 17px;
  font-weight: 400;
}
#contents .worksDetailWrap .memberArea .portlate .right .name {
  height: 21px;
}
#contents .worksDetailWrap .memberArea .portlate .right .name img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
#contents .worksDetailWrap .memberArea .creditlist {
  padding-left: 3%;
  padding-right: 3%;
}
#contents .worksDetailWrap .memberArea .creditlist::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .memberArea .creditlist dt {
  width: 48%;
  height: 36px;
  float: left;
  font-size: 15px;
  line-height: 1.231;
  color: #888;
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
}
#contents .worksDetailWrap .memberArea .creditlist dd {
  width: 48%;
  height: 36px;
  float: right;
  font-size: 15px;
  line-height: 1.231;
}
#contents .worksDetailWrap .locationArea {
  margin-top: 210px;
  width: 100%;
  height: 525px;
  position: relative;
  display: table;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
#contents .worksDetailWrap .locationArea .tit {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#contents .worksDetailWrap .locationArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .worksDetailWrap .locationArea .tit .svg {
  width: auto;
  height: 23.5px;
  display: inline-block;
}
#contents .worksDetailWrap .locationArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .worksDetailWrap .mapArea {
  height: 828px;
  padding-left: 3%;
  padding-right: 3%;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.063);
  margin-bottom: 80px;
}
#contents .worksDetailWrap .mapArea .mapInner {
  width: 100%;
  height: 852px;
  background-color: #aaa;
  top: -52px;
  z-index: 2;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .worksDetailWrap .mapArea .mapInner #map {
  width: 100%;
  height: 100%;
}
#contents .worksDetailWrap .infoWrap {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 80px;
}
#contents .worksDetailWrap .infoInner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#contents .worksDetailWrap .infoInner::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .underLine {
  background: url("/img/dotline.png") 0 100% repeat-x;
  margin-bottom: 62px;
  width: 100%;
  height: 1px;
}
#contents .worksDetailWrap .infoArea {
  width: 48%;
}
#contents .worksDetailWrap .infoArea::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksDetailWrap .infoArea .left {
  width: 29%;
  float: left;
  position: relative;
  margin-bottom: 62px;
}
#contents .worksDetailWrap .infoArea .left img {
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  width: 100%;
  height: auto;
}
#contents .worksDetailWrap .infoArea .left .no {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 34px;
  height: 34px;
  background-color: #dec470;
  border-radius: 34px;
  display: block;
}
#contents .worksDetailWrap .infoArea .left .no img {
  height: 11px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#contents .worksDetailWrap .infoArea .right {
  width: 62%;
  float: right;
  margin-bottom: 62px;
}
#contents .worksDetailWrap .infoArea .right .tit {
  font-size: 15px;
  line-height: 1.77;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
}
#contents .worksDetailWrap .infoArea .right .txt {
  font-size: 13.5px;
  line-height: 1.77;
}
#contents .musicWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .musicWrap .readcopyArea {
  position: absolute;
  top: -138px;
  left: 50%;
  margin-left: 3%;
  width: 35%;
  padding: 66px 5%;
  background-color: #000;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  z-index: 3;
}
#contents .musicWrap .readcopyArea p {
  font-size: 13.5px;
  line-height: 1.77;
}
#contents .musicWrap .imgArea {
  margin-right: 30%;
  margin-bottom: 200px;
}
#contents .musicWrap .imgArea img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .musicWrap .scriptArea {
  padding-bottom: 160px;
}
#contents .musicWrap .scriptArea .kv {
  width: 100%;
  height: 600px;
  background: url("/img/music_photo_02.jpg") 50% 50% no-repeat;
  background-size: cover;
}
#contents .musicWrap .scriptArea .titWrap {
  margin-bottom: 80px;
  margin-top: -92px;
  padding-left: 18%;
}
#contents .musicWrap .scriptArea .titWrap .svg {
  width: auto;
  height: 175px;
  display: block;
}
#contents .musicWrap .scriptArea .titWrap .svg svg {
  width: 384.19px;
  height: 100%;
}
#contents .musicWrap .scriptArea .titWrap.en .svg svg {
  width: 351px;
}
#contents .musicWrap .scriptArea .scriptWrap {
  padding-left: 18%;
}
#contents .musicWrap .scriptArea .scriptWrap .svg {
  width: 590px;
  height: 1044px;
  display: block;
}
#contents .musicWrap .scriptArea .scriptWrap .svg svg {
  width: 100%;
  height: auto;
}
#contents .musicWrap .scriptArea .scriptWrap.en .svg {
  height: 1537px;
}
#contents .musicWrap .scriptArea .img01 {
  width: 328px;
  position: absolute;
  top: 520px;
  right: 9%;
}
#contents .musicWrap .scriptArea .img01 img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .musicWrap .scriptArea .img02 {
  width: 328px;
  position: absolute;
  top: 1320px;
  right: 9%;
}
#contents .musicWrap .scriptArea .img02 img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .musicWrap .middleHeadArea {
  width: 100%;
  height: 525px;
  position: relative;
  display: table;
  background: url("/img/music_photo_05.jpg") 50% 50% no-repeat;
  background-size: cover;
}
#contents .musicWrap .middleHeadArea .tit {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
#contents .musicWrap .middleHeadArea .tit .txt {
  display: block;
  position: absolute;
  left: -9999px;
}
#contents .musicWrap .middleHeadArea .tit .svg {
  width: auto;
  height: 23.5px;
  display: inline-block;
}
#contents .musicWrap .middleHeadArea .tit .svg svg {
  width: auto;
  height: 100%;
}
#contents .musicWrap .discographyArea {
  padding-left: 3%;
  padding-right: 3%;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.063);
}
#contents .musicWrap .discographyArea .discographyInner {
  background-color: #333;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  position: relative;
  top: -52px;
  padding-top: 228px;
  padding-bottom: 228px;
}
#contents .musicWrap .discographyArea .txt01 {
  font-size: 13.5px;
  line-height: 1.77;
  font-weight: bold;
  padding-left: 57%;
  padding-right: 10%;
}
#contents .musicWrap .discographyArea .txt01:after {
  content: '';
  display: block;
  width: 24px;
  height: 1px;
  opacity: 0.5;
  margin: 46px 0 32px 0;
  background-color: #fff;
}
#contents .musicWrap .discographyArea .txt02 {
  font-size: 13.5px;
  line-height: 1.77;
  padding-left: 57%;
  padding-right: 10%;
}
#contents .musicWrap .discographyArea .img01 {
  width: 28%;
  position: absolute;
  top: 228px;
  left: 15%;
}
#contents .musicWrap .discographyArea .img01 img {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
@-moz-keyframes fadeOpacity_music_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_music_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_music_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_music_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes yanim_music_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes yanim_music_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes yanim_music_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@keyframes yanim_music_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(1) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.125s, 0.125s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(2) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.15s, 0.15s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(3) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.175s, 0.175s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(4) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.2s, 0.2s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(5) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.225s, 0.225s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(6) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.25s, 0.25s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(7) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.275s, 0.275s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(8) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.3s, 0.3s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(9) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.325s, 0.325s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(10) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.35s, 0.35s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(11) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.375s, 0.375s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(12) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.4s, 0.4s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(13) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.425s, 0.425s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .txt span:nth-of-type(14) {
  display: inline-block;
  animation-name: fadeOpacity_music_hover, yanim_music_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.45s, 0.45s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn:hover .bg circle {
  animation-name: rotate_play_hover, offset_play_hover;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
#contents .musicWrap .discographyArea .btn {
  width: 186px;
  height: 186px;
  position: absolute;
  top: -25px;
  right: 55px;
  display: block;
  cursor: pointer;
}
#contents .musicWrap .discographyArea .btn .underline {
  position: absolute;
  top: 112px;
  left: 50%;
  width: 146px;
  height: 1px;
  background-color: #fff;
  margin-left: -73px;
}
#contents .musicWrap .discographyArea .btn .txt {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1em;
  margin-top: -0.5em;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 12.5px;
  line-height: 1.231;
}
@-moz-keyframes fadeOpacity_music {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_music {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_music {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_music {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes yanim_music {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes yanim_music {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes yanim_music {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@keyframes yanim_music {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(1) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.125s, 0.125s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(2) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.15s, 0.15s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(3) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.175s, 0.175s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(4) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.2s, 0.2s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(5) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.225s, 0.225s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(6) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.25s, 0.25s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(7) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.275s, 0.275s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(8) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.3s, 0.3s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(9) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.325s, 0.325s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(10) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.35s, 0.35s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(11) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.375s, 0.375s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(12) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.4s, 0.4s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(13) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.425s, 0.425s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .txt span:nth-of-type(14) {
  display: inline-block;
  animation-name: fadeOpacity_music, yanim_music;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.45s, 0.45s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .musicWrap .discographyArea .btn .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#contents .musicWrap .discographyArea .btn .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 585 585;
  transform-origin: center;
  animation-name: rotate_play, offset_play;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
#contents .locationWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .locationWrap .readcopyArea {
  position: absolute;
  top: -138px;
  left: 50%;
  margin-left: 3%;
  width: 35%;
  padding: 66px 5%;
  background-color: #000;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
  z-index: 3;
}
#contents .locationWrap .readcopyArea p {
  font-size: 13.5px;
  line-height: 1.77;
}
#contents .locationWrap .mapArea {
  height: 828px;
  padding-left: 3%;
  padding-right: 3%;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.063);
  margin-bottom: 80px;
}
#contents .locationWrap .mapArea .mapInner {
  width: 100%;
  height: 852px;
  background-color: #aaa;
  top: -52px;
  z-index: 2;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .locationWrap .mapArea .mapInner #map {
  width: 100%;
  height: 100%;
}
#contents .locationWrap .infoWrap {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 80px;
}
#contents .locationWrap .infoInner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#contents .locationWrap .underLine {
  background: url("/img/dotline.png") 0 100% repeat-x;
  margin-bottom: 62px;
  width: 100%;
  height: 1px;
}
#contents .locationWrap .infoArea {
  width: 48%;
}
#contents .locationWrap .infoArea .left {
  width: 29%;
  float: left;
  position: relative;
  margin-bottom: 62px;
}
#contents .locationWrap .infoArea .left img {
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  width: 100%;
  height: auto;
}
#contents .locationWrap .infoArea .left .no {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 34px;
  height: 34px;
  background-color: #dec470;
  border-radius: 34px;
  display: block;
}
#contents .locationWrap .infoArea .left .no img {
  height: 11px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
#contents .locationWrap .infoArea .right {
  width: 62%;
  float: right;
  margin-bottom: 62px;
}
#contents .locationWrap .infoArea .right .tit {
  font-size: 15px;
  line-height: 1.77;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
}
#contents .locationWrap .infoArea .right .txt {
  font-size: 13.5px;
  line-height: 1.77;
}
#contents .worksWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .worksWrap .contentsBody .worksList {
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 160px;
}
#contents .worksWrap .contentsBody .worksList::after {
  content: "";
  clear: both;
  display: block;
}
#contents .worksWrap .contentsBody .list {
  float: left;
  width: 28%;
  text-align: center;
  transform-style: preserve-3d;
  perspective: 700;
}
#contents .worksWrap .contentsBody .list img {
  pointer-events: none;
}
#contents .worksWrap .contentsBody .list:nth-child(3n-1),
#contents .worksWrap .contentsBody .list:nth-child(3n-2) {
  margin-right: 8%;
}
#contents .worksWrap .contentsBody .list .no {
  font-size: 11px;
  line-height: 1.231;
  display: block;
  margin: 0 auto;
}
#contents .worksWrap .contentsBody .list .no:after {
  display: block;
  content: '';
  width: 10px;
  height: 1px;
  transform: matrix3d(0.87, -0.5, 0, 0, 0.5, 0.87, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  margin: 12px auto 19px auto;
  opacity: 0.5;
}
#contents .worksWrap .contentsBody .list .tit.twoline {
  height: 30px;
}
#contents .worksWrap .contentsBody .list .tit {
  height: 15px;
  display: block;
  width: 100%;
  margin-bottom: 40px;
}
#contents .worksWrap .contentsBody .list .tit img {
  width: auto;
  height: 100%;
  display: block;
  margin: 0 auto;
}
#contents .worksWrap .contentsBody .list .credit {
  display: block;
  margin: 0 auto;
  font-size: 11px;
  line-height: 1.231;
}
#contents .worksWrap .contentsBody .list.item01 {
  margin-top: -180px;
}
#contents .worksWrap .contentsBody .list.item02 {
  margin-top: -80px;
}
#contents .worksWrap .contentsBody .list.item03 {
  margin-top: 26px;
}
#contents .worksWrap .contentsBody .list.item01,
#contents .worksWrap .contentsBody .list.item02,
#contents .worksWrap .contentsBody .list.item03 {
  margin-bottom: 100px;
}
#contents .worksWrap .contentsBody .list.item01 a,
#contents .worksWrap .contentsBody .list.item02 a,
#contents .worksWrap .contentsBody .list.item03 a {
  color: #fff;
  display: block;
  width: 100%;
}
#contents .worksWrap .contentsBody .list.item01 .thumb,
#contents .worksWrap .contentsBody .list.item02 .thumb,
#contents .worksWrap .contentsBody .list.item03 .thumb {
  width: 100%;
  height: auto;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  margin-bottom: 63px;
}
#contents .worksWrap .contentsBody .list.item01 .no:after,
#contents .worksWrap .contentsBody .list.item02 .no:after,
#contents .worksWrap .contentsBody .list.item03 .no:after {
  background-color: #fff;
}
#contents .worksWrap .contentsBody .list.item-no-video {
  margin-bottom: 84px;
}
#contents .worksWrap .contentsBody .list.item-no-video .back-rect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  background-color: #fff;
  backface-visibility: hidden;
}
#contents .worksWrap .contentsBody .list.item-no-video a {
  color: #000;
  width: 100%;
  display: block;
  position: relative;
}
#contents .worksWrap .contentsBody .list.item-no-video a:before {
  content: "";
  display: block;
  padding-top: 100%;
}
#contents .worksWrap .contentsBody .list.item-no-video .inner {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -56.5px;
}
#contents .worksWrap .contentsBody .list.item-no-video .inner.twoline {
  margin-top: -64px;
}
#contents .worksWrap .contentsBody .list.item-no-video .no:after {
  background-color: #000;
}
#contents .aboutWrap .contentsBody {
  padding-bottom: 1px;
}
#contents .aboutWrap .readcopyArea {
  position: absolute;
  top: -138px;
  left: 50%;
  margin-left: 3%;
  width: 35%;
  padding: 66px 5%;
  background-color: #000;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
}
#contents .aboutWrap .readcopyArea p {
  font-size: 13.5px;
  line-height: 1.77;
}
#contents .aboutWrap .contentsArea {
  position7: relative;
  box-sizing: border-box;
}
#contents .aboutWrap .contentsArea .no {
  width: auto;
  height: 185px;
  position: absolute;
}
#contents .aboutWrap .contentsArea .no svg {
  width: auto;
  height: 100%;
}
#contents .aboutWrap .contentsArea .tit {
  font-size: 15px;
  line-height: 1.77;
  margin-bottom: 35px;
  text-transform: uppercase;
  letter-spacing: 3.5px;
}
#contents .aboutWrap .contentsArea .txt {
  font-size: 13.5px;
  line-height: 1.77;
}
#contents .aboutWrap .contentsArea .line {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 1px;
  height: 131px;
  background-color: #fff;
  margin-top: -131px;
  opacity: 0.5;
}
#contents .aboutWrap .contentsArea.item01 {
  padding-top: 280px;
  padding-right: 57%;
  padding-bottom: 204px;
  padding-left: 18%;
}
#contents .aboutWrap .contentsArea.item01 .no {
  top: 164px;
  left: -5%;
}
#contents .aboutWrap .contentsArea.item02 {
  width: 100%;
  height: 491px;
  overflow: hidden;
}
#contents .aboutWrap .contentsArea.item02 .photo {
  width: 633px;
  height: 396px;
  background-color: #000;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
}
#contents .aboutWrap .contentsArea.item02 .photo img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: auto;
}
#contents .aboutWrap .contentsArea.item02 .photo:nth-child(1) {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -1054px;
}
#contents .aboutWrap .contentsArea.item02 .photo:nth-child(2) {
  position: absolute;
  top: 48px;
  left: 50%;
  margin-left: -316px;
}
#contents .aboutWrap .contentsArea.item02 .photo:nth-child(3) {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: 421px;
}
#contents .aboutWrap .contentsArea.item03 {
  padding-top: 280px;
  padding-right: 24%;
  padding-bottom: 204px;
  padding-left: 50%;
  z-index: 2;
}
#contents .aboutWrap .contentsArea.item03 .no {
  top: 164px;
  left: 26%;
}
#contents .aboutWrap .contentsArea.item04 {
  padding-top: 158px;
  padding-right: 1%;
  padding-bottom: 158px;
  padding-left: 1%;
  background-color: #000;
  z-index: 1;
  margin-top: -32px;
}
#contents .aboutWrap .contentsArea.item04 img {
  width: 100%;
  height: auto;
}
#contents .aboutWrap .contentsArea.item05 {
  padding-top: 122px;
  padding-right: 40%;
  padding-bottom: 178px;
  padding-left: 34%;
  z-index: 2;
  margin-top: -228px;
  margin-bottom: 92px;
}
#contents .aboutWrap .contentsArea.item05 .no {
  top: 0;
  left: 10%;
}
#contents .aboutWrap .contentsArea.item06 img {
  width: 100%;
  height: auto;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .photo {
  filter: brightness(0.8);
}
@-moz-keyframes fadeOpacity_play_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_play_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_play_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_play_hover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes yanim_play_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes yanim_play_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes yanim_play_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@keyframes yanim_play_hover {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(1) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.125s, 0.125s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(2) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.15s, 0.15s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(3) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.175s, 0.175s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(4) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.2s, 0.2s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(5) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.225s, 0.225s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(6) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.25s, 0.25s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(7) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.275s, 0.275s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(8) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.3s, 0.3s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .txt span:nth-of-type(9) {
  animation-name: fadeOpacity_play_hover, yanim_play_hover;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.325s, 0.325s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .img:hover .btn .bg circle {
  animation-name: rotate_play_hover, offset_play_hover;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_play_hover {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes offset_play_hover {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
#contents .aboutWrap .contentsArea.item06 .img {
  position: relative;
  margin-right: 10%;
  margin-left: 10%;
  display: block;
}
#contents .aboutWrap .contentsArea.item06 .img .photo {
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #000;
  margin-bottom: 113px;
  box-shadow: 0 13px 25px 0 rgba(0,0,0,0.2);
  transition: filter 0.3s ease 0s;
}
#contents .aboutWrap .contentsArea.item06 .btn {
  width: 186px;
  height: 186px;
  position: absolute;
  bottom: 25px;
  right: 25px;
  display: block;
  cursor: pointer;
}
#contents .aboutWrap .contentsArea.item06 .btn .underline {
  position: absolute;
  top: 112px;
  left: 50%;
  width: 146px;
  height: 1px;
  background-color: #fff;
  margin-left: -73px;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1em;
  margin-top: -0.5em;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 12.5px;
  line-height: 1.231;
}
@-moz-keyframes fadeOpacity_play {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOpacity_play {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeOpacity_play {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOpacity_play {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes yanim_play {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-webkit-keyframes yanim_play {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@-o-keyframes yanim_play {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
@keyframes yanim_play {
  from {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(1) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.125s, 0.125s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(2) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.15s, 0.15s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(3) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.175s, 0.175s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(4) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.2s, 0.2s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(5) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.225s, 0.225s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(6) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.25s, 0.25s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(7) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.275s, 0.275s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(8) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.3s, 0.3s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span:nth-of-type(9) {
  animation-name: fadeOpacity_play, yanim_play;
  animation-duration: 1s, 1s;
  animation-timing-function: ease, ease;
  animation-delay: 0.325s, 0.325s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
#contents .aboutWrap .contentsArea.item06 .btn .txt span {
  display: inline-block;
}
#contents .aboutWrap .contentsArea.item06 .btn .bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#contents .aboutWrap .contentsArea.item06 .btn .bg circle {
  fill: transparent;
  stroke: #fff;
  stroke-width: 0.5;
  stroke-dasharray: 585 585;
  transform-origin: center;
  animation-name: rotate_play, offset_play;
  animation-duration: 1s, 0.8s;
  animation-timing-function: ease, cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-delay: 0s, 0.1s;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
  animation-play-state: running;
}
@-moz-keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-webkit-keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-o-keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@keyframes rotate_play {
  0% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 1, 180deg);
  }
}
@-moz-keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes offset_play {
  from {
    stroke-dashoffset: 585;
  }
  to {
    stroke-dashoffset: 0;
  }
}
body .back {
  transform: matrix3d(0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, -60, 0, 1);
  transform-origin: left bottom 0px;
  display: block;
  width: 120px;
  height: 57px;
  position: fixed;
  top: calc(50vh - 60px);
  left: 0;
  z-index: 5;
}
body .back .txt {
  font-size: 11px;
  line-height: 1.231;
  display: block;
  position: relative;
  text-align: center;
  color: #fff;
}
body .back .line {
  height: 32px;
  width: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
}
