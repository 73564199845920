@import "./utils/_reset"
@import "./utils/_mixins"
@import "./utils/_animation"
@import "./utils/_keyframes"
@import "./utils/_fonts"

$font= 'Roboto', 'YakuHanJP', 'NotoSansJP', sans-serif
$font_serif= 'Libre Baskerville', serif

body,html
  // height: 100%
  min-width: 995px
  min-height: 600px
  font-family: $font
  font-weight: 100


html
  overflow-y: scroll

body
  position: relative
  outline: none
  color: #000
  background: #fff
  text-align: left
  line-height: 1.231
  -webkit-text-size-adjust: none
  letter-spacing: 2.5px
  // overflow: hidden

.blur-anim-text:hover
  animation-name: blurAnim
  animation-duration: 0.6s
  animation-timing-function: cubic-bezier(0.3, 0.1, 0.2, 1.0)
  animation-iteration-count: 1

//------------------------------------------------------------
//  style
//------------------------------------------------------------
// #pageWrap
  // pointer-events: none
  // display: none
@import "./components/_movieCanvas"


.page-top
  user-select: none;
  touch-callout: none;

#cursor.normal
  opacity: 1

#loading
  position: fixed
  width: 100%
  height: 100%
  top:0
  left: 0
  background: #fff
  z-index: 9999999
  opacity: 1

  .line
    width: 0%
    height: 1px
    background-color: #ccc
    top: calc(50% - 1px)
  .line.auto
    width: 50%
    widthanim(0%, 50%, 'loading')
    animationParams(widthanimloading, 3s, linear)

//nowebgl
body.no-gl
  #wrap
    .enterWrap
      background-color: #fff
      .enter
        display: none
      $diff = 90px
      .logo
        margin-top: (-204px - $diff)
      .read
        margin-top: (145px - $diff)
      nav.nav
        width: 519px
        height: 44px
        border-top: 2px solid #ccc
        border-bottom: 2px solid #ccc
        display: block
        position: absolute
        top: 50%
        left: 50%
        margin-left: -259.5px
        margin-top: (257px - $diff)
        padding: 0 50px 0 48px
        ul

          li
            display: inline-block
            line-height: 42px
            font-size: -1
            letter-spacing: 0em
            font-weight: 400
            a
              letter-spacing: 2.5px
              color: #000
          li:nth-child(n+2)
            margin-left: 38px
      .nogl-description
        display: block
        position: absolute
        height: 55px
        width: 100%
        background: #e5e5e5
        bottom: 0
        text-align: center
        p
          font-size: 13px
          line-height: 55px
          color: #888


.sp
  display: none !important
#wrap
  .chapter
    a
      color: #000
    .contentsWrap
      .nav
        li
          div
            color: #000
          a
            color: #000
      .sound .equalizer span
        background-color: #000
      .sound .bg circle
        stroke: #000
      .switch .txt span
        background-color: #000
      .switch .bg circle
        stroke: #000
      .sns li a
        color: #000
      .other li a
        color: #000
      h1.logo path
        fill: #000
#wrap
  position: relative
  background-color: #ccc
  width: 100%
  height: 100%
  // overflow-x: hidden
  color: #fff
  a
    color: #fff

  .enterWrap
    position: absolute
    top: 0
    left: 0
    z-index: 10
    width: 100%
    height: 100vh

    .nogl-description
      display: none
    nav.nav
      display: none

    .logo
      position: absolute
      top: 50%
      left: 50%
      margin-top: -204px
      margin-left: -221px
      width: 440px
      height: 272px
      // background: url("/svg/logo_01.svg") no-repeat 0 0
      // background-size: 100% auto
      text-indent: -9999px
      position: relative
      letter-spacing: 0
      line-height: 0

      svg
        width: 100%
        height: 100%
        path
          fill: #333

    .read
      position: absolute
      top: 50%
      left: 50%
      margin-top: 145px
      margin-left: -134px
      width: 268px
      height: 10px
      font-size: 13px
      line-height: 1.231
      text-align: center
      letter-spacing: 1px
      color: #000

      &:before
        content: ""
        position: absolute
        top: 0.5em
        left: 0
        width: 44px
        height: 1px
        background-color: #000
        display: block

      &:after
        content: ""
        position: absolute
        top: 0.5em
        right: 0
        width: 44px
        height: 1px
        background-color: #000
        display: block

    .enter:hover
      .bg
        circle
          fill: #fff
      .txt
        color: #000
    .enter
      position: absolute
      bottom: 36px
      left: 50%
      margin-left: -48px
      display: table
      width: 96px
      height: 96px
      cursor: pointer

      .txt
        position: relative
        display: table-cell
        vertical-align: middle
        text-align: center
        font-size: 12px
        line-height: 1.5
        color: #fff
        z-index: 2
        font-weight: 100
        transition: color 0.3s ease 0s

      .bg
        position: absolute
        top: 0
        left: 0
        z-index: 1

        circle
          fill: #000
          stroke: #000
          stroke-width: 1
          stroke-dasharray: 303
          stroke-dashoffset: 0
          transform: rotateZ(-90deg)
          transform-origin: center
          transition: fill 0.3s ease 0s
        circle.back
          transition: all 0.5s linear 0s

  .contentsWrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100vh
    z-index: 0
    //tmp
    // background-color: #ddd

    .logo
      position: absolute
      top: 39px
      left: 68px
      width: 114px
      height: 59px
      z-index: 1000
      // background: url("../svg/logo_02.svg") no-repeat 0 0
      // background-size: 100% auto
      text-indent: -9999px
      svg
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0



    .nav
      position: absolute
      top: 70px
      left: 0
      z-index: 99
      width: 100%
      display: flex
      justify-content: center
      ul
        display: flex
      li
        font-size: 14px
        line-height: 1.231
        font-weight: 100
        margin-right: 52px
        &:first-child
          margin-right: 58px
        &:nth-child(3)
          margin-right: 60px
        &:last-child
          margin-right: 0
        div
          color: #fff
          cursor: default
        a
          color: #fff

    .sound
      position: absolute
      top: 52px
      right: 70px
      z-index: 100
      width: 32px
      height: 32px
      display: block
      cursor: pointer

      .equalizer
        position: relative
        z-index: 2
        width: 32px
        height: 32px
        display: block

        span
          width: 1px
          height: 1px
          background-color: #fff
          position: absolute
          bottom: 12px
          heightanim(1px, 8px, "-equalizer")

        $lefts = 9px 12px 15px 18px 21px
        $timeScale = 0.8 0.5 0.6 0.9 1.2
        $delaystep = 0.2
        $time = 0.4s
        for $left, $i in $lefts
          span:nth-of-type({$i+1})
            left: $left
            animationParams(heightanim-equalizer, ($time*$timeScale), linear, ($delaystep*$i)s, infinite, alternate, none)

      .bg
        position: absolute
        top: 0
        left: 0
        z-index: 1
        transform-origin: center
        transition: transform 0.2s ease 0.2s

        circle
          fill: transparent
          stroke: #fff
          stroke-width: 0.5
          stroke-dasharray: 377 377

          transform-origin: center

    .sound:hover
      .bg
        transform: scale(1.2)
        circle
          rotateanim(0, 90, "sound")
          svgOffset(377, 172, "sound")
          $rotate = rotate_sound 1.0s ease 0s 1 normal forwards
          $offset = offset_sound 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
          animationParamsArray(($rotate $offset))

    .sound.muted
      .equalizer
        span
          animation: none

    .other
      position: absolute
      z-index: 100
      bottom: 63px
      left: 70px

      li
        font-size: 11px
        line-height: 1.231
        font-weight: 100

        a
          color: #fff

    .sns
      position: absolute
      z-index: 100
      bottom: 63px
      right: 70px

      li
        font-size: 11px
        line-height: 1.231
        font-weight: 100

        a
          color: #fff

    .switch:hover
      .txt
        fadeOpacity(0, 1, switch_hover)
        span:nth-of-type(1)
          animationParams(fadeOpacity_switch_hover, 1.0s, ease, 0.1s, 1, normal, forwards)
        span:nth-of-type(2)
          animationParams(fadeOpacity_switch_hover, 1.0s, ease, 0.15s, 1, normal, forwards)
        span:nth-of-type(3)
          animationParams(fadeOpacity_switch_hover, 1.0s, ease, 0.2s, 1, normal, forwards)

      .bg
        transform: scale(1.2)
        circle
          rotateanim(0, 90, "switch")
          svgOffset(377, 172, "switch")
          $rotate = rotate_switch 1.0s ease 0s 1 normal forwards
          $offset = offset_switch 0.8s EASEINOUTCIRC 0.1s 1 normal forwards
          animationParamsArray(($rotate $offset))


          // animationParams(offset_switch, 0.8s, EASEINOUTCIRC, 0.1s, 1, normal, forwards)
    .switch
      width: 66px
      height: 66px
      position: absolute
      left: 50%
      bottom: 44px
      margin-left: -33px
      z-index: 100

      a
        display: block
        width: 66px
        height: 66px
        position: relative
        cursor: pointer
      .txt
        position: relative
        display: block
        top: 0
        left: 0
        z-index: 2
        width: 66px
        height: 66px
        span
          position: absolute
          top: 31px
          background-color: #fff
          width: 4px
          height: 4px
          fadeOpacity(0, 1, switch)
        span:nth-of-type(1)
          animationParams(fadeOpacity_switch, 1.0s, ease, 0.1s, 1, normal, forwards)
          left: 23px

        span:nth-of-type(2)
          animationParams(fadeOpacity_switch, 1.5s, ease, 0.1s, 1, normal, forwards)
          left: 30px

        span:nth-of-type(3)
          animationParams(fadeOpacity_switch, 2.0s, ease, 0.1s, 1, normal, forwards)
          left: 38px

      .bg
        position: absolute
        top: 0
        left: 0
        z-index: 1
        transition: transform 0.2s ease 0.2s
        transform-origin: center
        circle
          fill: transparent
          stroke: #fff
          stroke-width: 0.5
          stroke-dasharray: 377 377
          stroke: #fff
          transform-origin: center


    .seek
      position: absolute
      left: 0
      top: 50%
      width: 100%
      height: 1px
      z-index: 10
      pointer-events: none

      .seekbar
        position: absolute
        top: 0
        left: 0
        background-color:  #ffffff30
        width: 100%
        height: 1px

      .line
        position: absolute
        top: 0
        left: 0
        background-color:  #fff
        width: 30%
        height: 1px

      .current
        position: absolute
        top: -3px
        left: 30%
        background-color:  #fff
        width: 1px
        height: 7px


    .movieArea
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: 1
      overflow: hidden
      perspective: 500
      -webkit-perspective: 500
      opacity: 0
      .movieInner
        position: absolute
        width: 100%
        height: 100%


      .item
        position: relative

        .embed
          background-color: #ccc
          position: absolute

        .name
          position: absolute
          bottom: 50%
          left: 0
          width: 100%
          text-align: center

          .tit
            position: absolute
            bottom: 77px
            left: 50%
            width: 300px
            margin-left: -150px
            font-size: 13px
            color: #fff
            line-height: 1.231
            letter-spacing: 5px

            a
              color: #fff

          .chapter
            position: absolute
            bottom: 40px
            left: 50%
            width: 300px
            margin-left: -150px
            font-size: 11px
            color: #ffffff30
            line-height: 1.231

        .state
          position: absolute
          top: 50%
          left: 0
          width: 100%
          text-align: center


          .neWrap
            top: 40px
            clearfix()
            floatCenterWrap()
            // width: 180px
            margin-bottom: 20px
            background-size: auto 100%

          .e
            floatCenterInner()
            font-size: 10px
            color: #ffffff30
            line-height: 1.231
            float: left
            // width: 50%
            margin-right: 8px

            &:after
              content: '/'
              margin-left: 8px

          .n
            floatCenterInner()
            font-size: 10px
            color: #ffffff30
            line-height: 1.231
            float: right
            // width: 50%

          .location
            position: absolute
            top: 70px
            left: 50%
            clear: both
            font-size: 13px
            color: #fff
            line-height: 1.231
            letter-spacing: 3px
            text-align: center
            width: 300px
            height: 19px
            margin-left: -150px
            padding-bottom: 5px

            a
              color: #fff

            .icon
              display: inline-block
              width: 24px
              height: 19px
              background: url("/img/icon_01.png") no-repeat 0 0
              background-size: auto 19px
              margin-bottom: -5px


      &.view
        z-index: 2
        color: #ddd
        .moveInner
          top: 50%
          left: 50%
          margin-left: -50%
          margin-top: -50%

        .item
          width: 100%
          height: 100%
          position: absolute


      &.thumb
        z-index: 1
        .scroller
          position: absolute
          top: 0
          left: 0
          width: 100%
          height: 100%
          z-index: 0
        .movieInner
          clearfix()
          z-index: 1
          width: auto
          height: 100%
          // margin-left: -1007px
          // margin-top: -79px
          // padding-right: 50%
          white-space: nowrap
          cursor: grab
          font-size: 0
          word-spacing: 0px
          letter-spacing: 0px
          // height: 160px

        .item:first-child
          margin-left: calc(50vw - 187.5px)
        .item:last-child
          margin-right: calc(50vw - 187.5px)

        $itemTextTransition = all 0.4s cubic-bezier(0.3, 0.1, 0.2, 1.0);
        $itemImageTransition = all 0.4s cubic-bezier(0.2, 0.5, 0.3, 1.0);
        .item
          width: 375px
          $height = 250px
          height: $height
          margin-right: 35px
          display: inline-block
          letter-spacing: 2.5px
          margin-top: "calc(50vh - %s)" % ($height / 2)
          vertical-align: top
          .name
            position:absolute
            bottom: 0
            top: calc(50% - 118px)
            transition: $itemTextTransition
            .tit
              position:relative
              bottom: 0
              a
                color: #00000030
                transition: $itemTextTransition
              span
                color: #00000030
                transition: $itemTextTransition
            .chapter
              opacity: 0
              position: relative
              bottom: 0
              transition: $itemTextTransition

          .state
            position: absolute
            top: calc(50% + 102px)
            transition: $itemTextTransition
            .location
              top: 0px
              position: relative
              .icon
                background-image: url("/img/icon_03.png")
                opacity: 0.3
                transition: $itemTextTransition
              a
                color: #00000030
                transition: $itemTextTransition

            .neWrap
              opacity: 0
              position: relative
              margin-bottom: 0
              top: 0
              transition: $itemTextTransition
              .n
                transition: $itemTextTransition
              .e
                transition: $itemTextTransition


          .embed
            width: 100%
            height: 158px
            top: "calc(50% - %s)" % (158px / 2)
            left: 0
            transform-origin: center
            background-color: rgba(0, 0, 0, 0)
            z-index: 2
            transition: $itemImageTransition
            position: absolute
            cursor: pointer

          &:hover
            $height = 400px
            height: $height
            margin-top: "calc(50vh - %s)" % ($height / 2)
            z-index: 10 !important
            .embed
              transform: scale(1.25) !important
              transform-origin: center

            .name
              transform: translate3d(0, -70px, 0)
              .tit
                a
                  color: #000
                span
                  color: #000
              .chapter
                margin-top: 20px
                opacity: 1
                color: #000
            .state
              transform: translate3d(0, 40px, 0)
              .location
                .icon
                  opacity: 1
                a
                  color: #000
              .neWrap
                margin-top: 20px
                opacity: 1
                .e
                  color: #000
                .n
                  color: #000


  .shareWrap
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100vh
    z-index: 1
    a
      color: #000


    .linkWrap
      clearfix()
      position: absolute
      top: 50%
      left: 0
      width: 100%
      margin-top: -0.5em

      .youtube,
      .twitter,
      .facebook
        font-size: 21px
        line-height: 1.231
        letter-spacing: 5px
        width: 33.333%
        float: left
        text-align: center

        a
          font-family: $font_serif

  .creditWrap
    position: relative
    z-index: 1

#spMenu
  display: none


@import "./utils/_adjust"
@import "./components/_underpage"


